var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSubDomain
    ? _c("a", { attrs: { href: _vm.url } }, [_vm._t("default")], 2)
    : _c("router-link", { attrs: { to: _vm.url } }, [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }