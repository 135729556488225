<template>
	<div class="head-cell-inner nowrap" :style="style">
		<span class="link" v-if="sort" @click="sortTable(sort, !sortRev)">
			<slot> </slot>
			<i
				v-if="qSort == sort"
				:class="'sort-icon zmdi ' + (app.query.sort.charAt(0) !== '-' ? 'zmdi-triangle-up' : 'zmdi-triangle-down')"
			></i>
		</span>
		<slot v-else>&nbsp;</slot>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				parentY: 0,
				offsetTop: 0,
				style: {}
			};
		},
		props: ['sort', 'sortRev', 'noSticky'],
		computed: {
			qSort() {
				if (!this.app.query.sort) return;
				return this.app.query.sort.replace(/^-/, '');
			}
		},
		methods: {
			tryRecalc() {
				let top;
				let bottomBottomWidth;
				let scrollY = this.scrollTop();
				let diff = scrollY - this.parentY + this.offsetTop - 1;

				if (diff > 0) {
					top = diff + 'px';
					bottomBottomWidth = '1px';
				} else {
					top = 0;
					bottomBottomWidth = 0;
				}

				this.$set(this.style, 'top', top);
				this.$set(this.style, 'border-bottom-width', bottomBottomWidth);
			},
			updateParentY() {
				let newParentY = this.$el.parentNode.getBoundingClientRect().top + this.scrollTop();
				if (newParentY !== this.parentY) {
					this.parentY = newParentY;
					this.tryRecalc();
				}
			}
		},
		mounted() {
			if (this.noSticky) return;

			this.updateParentY();
			setInterval(_ => this.updateParentY(), 2000);

			this.offsetTop = this.app.$refs.header.clientHeight;

			window.addEventListener('scroll', this.tryRecalc);
			window.addEventListener('resize', this.updateParentY);
		},
		destroyed() {
			if (this.noSticky) return;

			window.removeEventListener('scroll', this.tryRecalc);
			window.removeEventListener('resize', this.updateParentY);
		}
	}
</script>