<template >
	<div class="video-popup" >
		<a class="video-close" @click="close()"><img src="/img/testimonials/close.svg" alt=""></a>
		<div class="video-container">

				<div class="video-title">Testimonials</div>


			<iframe :src="url"  frameborder="0" id="popup1_redirect" allow="autoplay; encrypted-media">
			</iframe>

			<a class="video-btn-request" data-ll="5361">Request a Quote					<i class="zmdi zmdi-mail-send"></i>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['url'],
		methods: {
			close() {
				this.$emit('close');
			}
		}
	}
</script>

<style lang="less">
		.video-popup{
			width: 100vw;
			height: 100vh;
			position: fixed;
			background-color: rgba(0, 0, 0, 0.8);
			top: 0;
			left: 0;
			z-index: 10;

			display: flex;
			justify-content: center;
			align-items: center;
			.video-close{
				position: absolute;
				display: block;
				width: 20px;
				height: 20px;
				top: 20px;
				right: 30px;
				cursor: pointer;
				z-index: 1;
			}

			.video-title{
				font-weight: 300;
				font-size: 40px;
				color: #fff;
				text-align: center;
			}

			.video-btn-request{
				border: none;
				outline: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				text-decoration: none;
				width: auto;
				height: 40px;
				font-weight: 700;
				font-size: 16px;
				color: #fff;
				border-radius: 20px;
				background-color: #f39c12;
				margin-top: 25px;
				padding-left: 45px;
				padding-right: 45px;
				padding-top: 9px;
				padding-bottom: 9px;
				position: relative;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
			}


			.video-container {
				width: 1000px;
				height: 679px;
				z-index: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			iframe{
				width: inherit;
				height: inherit;
				border: none;
				outline: none;
				box-shadow: none;
				position: relative;
			}
			.popup__btn{
				display: block;
				width: 235px;
				margin-left: auto;
				margin-right: auto;
			}
			.popup__header{
				max-width: 100%;
			}
		}
		@media only screen and (max-width: 1000px){
			.video-popup{
				padding: 0;
				padding-top: 50px;
				align-items: center;

				.video-container{
					/*position: relative;*/
					width: 100%;
					/*height: 0;
					padding-bottom: 56.25%;*/
				}


			}

		}
		@media only screen and (max-width: 768px) {
			.video-popup {
				align-items: center;
				.popup__video-iframe {
					height: 340px;
				}
				.popup__close {
					top: 0px;
					right: 15px;
				}
			}

		}



</style>