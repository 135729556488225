var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rootDomain
    ? _c(
        "div",
        { ref: "app", class: _vm.getAppClass(), attrs: { id: "app" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading"
                }
              ],
              staticClass: "loading"
            },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c("div", {
            ref: "title",
            class: "title " + _vm.titleAddClass,
            style: _vm.titleStyle,
            on: {
              mouseover: function($event) {
                _vm.titleStyle = {}
              }
            }
          }),
          _vm._v(" "),
          _c("the-header", {
            ref: "header",
            attrs: {
              user: _vm.user,
              pages: _vm.topPages,
              "sidebar-status": _vm.getSidebarStatus()
            },
            on: {
              pay: _vm.onHeaderPay,
              login: function($event) {
                _vm.authForm = {}
              },
              logout: _vm.logout
            }
          }),
          _vm._v(" "),
          _vm.sidebarVisible
            ? _c("the-sidebar", {
                attrs: {
                  pages: _vm.sidebarPages,
                  "logged-in": !!_vm.idu,
                  minimized: _vm.sidebarMinimized
                },
                on: { toggle: _vm.toggleSidebar }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "content-wrapper" }, [
            _c(
              "div",
              { staticClass: "content" },
              [_c("router-view", { ref: "routerView" })],
              1
            )
          ]),
          _vm._v(" "),
          _vm.footerVisible
            ? _c("the-footer", {
                attrs: { "sidebar-status": _vm.getSidebarStatus() }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.authForm
            ? _c(
                "base-popup",
                {
                  attrs: { dark: "1" },
                  on: {
                    close: function($event) {
                      _vm.authForm = false
                    }
                  }
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(_vm._s(_vm.authForm.header || "Login"))
                  ]),
                  _vm._v(" "),
                  _c("the-auth-form", {
                    on: {
                      authstatuschanged: function($event) {
                        _vm.authForm = null
                        _vm.refreshUserStatus()
                      },
                      modechanged: _vm.onAuthModeChanged
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.modalPopup.type
            ? _c(
                "base-popup",
                {
                  attrs: { width: "400", cls: "modal-popup" },
                  on: {
                    close: function($event) {
                      _vm.modalPopup.type === "prompt"
                        ? _vm.launchModalPopup(null)
                        : _vm.modalPopup.type === "confirm"
                          ? _vm.launchModalPopup(false)
                          : _vm.launchModalPopup(undefined)
                      _vm.modalPopup.type = null
                    }
                  }
                },
                [
                  _vm.modalPopup.type === "alert"
                    ? [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_vm._v("Alert")]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("output", [_vm._v(_vm._s(_vm.modalPopup.msg))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal-popup-button-box" },
                            [
                              _c(
                                "base-btn",
                                {
                                  directives: [
                                    { name: "focus", rawName: "v-focus" }
                                  ],
                                  staticClass: "modal-ok",
                                  attrs: { size: "small" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.modalPopup.type = null
                                      _vm.launchModalPopup(undefined)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-check" }),
                                  _vm._v(" OK")
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.modalPopup.type === "prompt"
                    ? [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_vm._v("Input")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "esc",
                                    27,
                                    $event.key,
                                    "Escape"
                                  )
                                ) {
                                  return null
                                }
                                _vm.modalPopup.type = null
                                _vm.launchModalPopup(null)
                              }
                            }
                          },
                          [
                            _c("output", [
                              _vm._v(_vm._s(_vm.modalPopup.promptTitle))
                            ]),
                            _vm._v(" "),
                            _vm.modalPopup.promptType
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.modalPopup.msg,
                                      expression: "modalPopup.msg"
                                    },
                                    { name: "focus", rawName: "v-focus" }
                                  ],
                                  staticClass: "area-prompt",
                                  domProps: { value: _vm.modalPopup.msg },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.modalPopup,
                                        "msg",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.modalPopup.msg,
                                      expression: "modalPopup.msg"
                                    },
                                    { name: "focus", rawName: "v-focus" }
                                  ],
                                  staticClass: "input-prompt",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.modalPopup.msg },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.modalPopup.type = null
                                      _vm.launchModalPopup(_vm.modalPopup.msg)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.modalPopup,
                                        "msg",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "modal-popup-button-box" },
                              [
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "modal-ok",
                                    attrs: { size: "small" },
                                    nativeOn: {
                                      click: function($event) {
                                        _vm.modalPopup.type = null
                                        _vm.launchModalPopup(_vm.modalPopup.msg)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" OK")
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "base-btn",
                                  {
                                    staticClass: "modal-cancel",
                                    attrs: { size: "small" },
                                    nativeOn: {
                                      click: function($event) {
                                        _vm.modalPopup.type = null
                                        _vm.launchModalPopup(null)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-remove" }),
                                    _vm._v(" Cancel")
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.modalPopup.type === "confirm"
                    ? [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_vm._v("Confirm")]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("output", [_vm._v(_vm._s(_vm.modalPopup.msg))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal-popup-button-box" },
                            [
                              _c(
                                "base-btn",
                                {
                                  directives: [
                                    { name: "focus", rawName: "v-focus" }
                                  ],
                                  staticClass: "modal-ok",
                                  attrs: { size: "small" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.modalPopup.type = null
                                      _vm.launchModalPopup(true)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-check" }),
                                  _vm._v(" OK")
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "base-btn",
                                {
                                  staticClass: "modal-cancel",
                                  attrs: { size: "small" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.modalPopup.type = null
                                      _vm.launchModalPopup(false)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-remove" }),
                                  _vm._v(" Cancel")
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("iframe", {
            attrs: { id: "download-frame", name: "download-frame" }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-inner" }, [
      _c("i", { staticClass: "fa fa-spinner spin-fast" }),
      _vm._v("\n\t\t\tLoading...\n\t\t")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }