var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "head-cell-inner nowrap", style: _vm.style },
    [
      _vm.sort
        ? _c(
            "span",
            {
              staticClass: "link",
              on: {
                click: function($event) {
                  _vm.sortTable(_vm.sort, !_vm.sortRev)
                }
              }
            },
            [
              _vm._t("default"),
              _vm._v(" "),
              _vm.qSort == _vm.sort
                ? _c("i", {
                    class:
                      "sort-icon zmdi " +
                      (_vm.app.query.sort.charAt(0) !== "-"
                        ? "zmdi-triangle-up"
                        : "zmdi-triangle-down")
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._t("default", [_vm._v(" ")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }