var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v("Permission denied. Please "),
    _c("span", { staticClass: "link", on: { click: _vm.authorize } }, [
      _vm._v("authorize")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }