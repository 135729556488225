var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar" }, [
    _c("div", { staticClass: "dp-calendar" }, [
      _vm.withTime
        ? _c(
            "div",
            { staticClass: "dp-time" },
            [
              _c("rich-sel", {
                attrs: { items: _vm.timesSuggestions },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dp-controls" }, [
        _c("div", { staticClass: "dp-angles-left" }, [
          _c("i", {
            staticClass: "fa fa-angle-double-left",
            on: {
              click: function($event) {
                _vm.changeYear(-1)
              }
            }
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "fa fa-angle-left",
            on: {
              click: function($event) {
                _vm.changeMonth(-1)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center general-upper" }, [
          _vm._v(
            _vm._s(_vm.stringIndexToMonthName(_vm.month)) +
              " " +
              _vm._s(_vm.year)
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dp-angles-right" }, [
          _c("i", {
            staticClass: "fa fa-angle-right",
            on: {
              click: function($event) {
                _vm.changeMonth(1)
              }
            }
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "fa fa-angle-double-right",
            on: {
              click: function($event) {
                _vm.changeYear(1)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("table", [
          _c("thead", [
            _c(
              "tr",
              _vm._l(_vm.weekDays, function(day) {
                return _c("th", [_vm._v(_vm._s(day))])
              })
            )
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.grid, function(row) {
              return _c(
                "tr",
                _vm._l(row, function(cell) {
                  return _c(
                    "td",
                    {
                      class: _vm.getCellClasses(cell),
                      on: {
                        click: function($event) {
                          _vm.onCellClick(cell)
                        }
                      }
                    },
                    [cell ? _c("span", [_vm._v(_vm._s(cell.day))]) : _vm._e()]
                  )
                })
              )
            })
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }