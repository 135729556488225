var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-box" }, [
    _c(
      "div",
      { staticClass: "auth-text" },
      [
        _vm.mode === "forgot"
          ? [
              _vm._v(
                "\n\t\t\tEnter your e-mail, and you will get instructions to restore access to your account.\n\t\t"
              )
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "auth-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _vm.mode === "login"
          ? _c(
              "div",
              [
                _c("div", { staticClass: "auth-input-row" }, [
                  _vm._m(0),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "E-mail",
                      name: "email",
                      disabled: _vm.isLoading
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "auth-input-row" }, [
                  _vm._m(1),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    attrs: {
                      type: "password",
                      placeholder: "Password",
                      name: "password",
                      disabled: _vm.isLoading
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginErrors.length,
                        expression: "loginErrors.length"
                      }
                    ],
                    staticClass: "auth-error-box"
                  },
                  _vm._l(_vm.loginErrors, function(err, index) {
                    return _c("div", { key: index, staticClass: "auth-err" }, [
                      _vm._v(_vm._s(err))
                    ])
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "auth-btn-row text-center" },
                  [
                    _c(
                      "base-btn",
                      { attrs: { submit: "", loading: _vm.isLoading } },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isLoading,
                              expression: "!isLoading"
                            }
                          ],
                          staticClass: "fa fa-fw fa-sign-in"
                        }),
                        _vm._v("\n\t\t\t\t\tLogin\n\t\t\t\t")
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "auth-forgot-row" }, [
                  _c(
                    "span",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          _vm.setMode("forgot")
                        }
                      }
                    },
                    [_vm._v("Forgot password?")]
                  )
                ]),
                _vm._v(" "),
                _c("soc-login"),
                _vm._v(" "),
                _c("div", { staticClass: "auth-alt-option" }, [
                  _vm._v("\n\t\t\t\tHave no account? "),
                  _c(
                    "span",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          _vm.setMode("register")
                        }
                      }
                    },
                    [_vm._v("Register")]
                  )
                ])
              ],
              1
            )
          : _vm.mode === "register"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "auth-input-row" }, [
                    _vm._m(2),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regUsername,
                          expression: "regUsername"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "Name",
                        name: "username",
                        disabled: _vm.isLoading
                      },
                      domProps: { value: _vm.regUsername },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.regUsername = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "auth-input-row" }, [
                    _vm._m(3),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regEmail,
                          expression: "regEmail"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "E-mail",
                        name: "email",
                        disabled: _vm.isLoading
                      },
                      domProps: { value: _vm.regEmail },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.regEmail = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "auth-input-row" }, [
                    _vm._m(4),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regPassword,
                          expression: "regPassword"
                        }
                      ],
                      attrs: {
                        type: "password",
                        placeholder: "Password",
                        name: "password",
                        disabled: _vm.isLoading
                      },
                      domProps: { value: _vm.regPassword },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.regPassword = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "auth-input-row" }, [
                    _vm._m(5),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regConfirm,
                          expression: "regConfirm"
                        }
                      ],
                      attrs: {
                        type: "password",
                        placeholder: "Confirm password",
                        name: "configm",
                        disabled: _vm.isLoading
                      },
                      domProps: { value: _vm.regConfirm },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.regConfirm = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loginErrors.length,
                          expression: "loginErrors.length"
                        }
                      ],
                      staticClass: "auth-error-box"
                    },
                    _vm._l(_vm.loginErrors, function(err, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "auth-err" },
                        [_vm._v(_vm._s(err))]
                      )
                    })
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "auth-btn-row text-center" },
                    [
                      _c(
                        "base-btn",
                        { attrs: { submit: "", loading: _vm.isLoading } },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            staticClass: "fa fa-fw fa-plus"
                          }),
                          _vm._v("\n\t\t\t\t\tCreate account\n\t\t\t\t")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("soc-login"),
                  _vm._v(" "),
                  _c("div", { staticClass: "auth-alt-option" }, [
                    _vm._v("\n\t\t\t\tAlready have an account? "),
                    _c(
                      "span",
                      {
                        staticClass: "link",
                        on: {
                          click: function($event) {
                            _vm.setMode("login")
                          }
                        }
                      },
                      [_vm._v("Login")]
                    )
                  ])
                ],
                1
              )
            : _vm.mode === "forgot"
              ? _c("div", [
                  _c("div", { staticClass: "auth-input-row" }, [
                    _vm._m(6),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.remindEmail,
                          expression: "remindEmail"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "E-mail",
                        name: "email",
                        disabled: _vm.isLoading
                      },
                      domProps: { value: _vm.remindEmail },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.remindEmail = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "auth-btn-row text-center" },
                    [
                      _c(
                        "base-btn",
                        { attrs: { submit: "", loading: _vm.isLoading } },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            staticClass: "fa fa-fw fa-send"
                          }),
                          _vm._v("\n\t\t\t\t\tSend\n\t\t\t\t")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "auth-back-row" }, [
                    _c(
                      "span",
                      {
                        staticClass: "link",
                        on: {
                          click: function($event) {
                            _vm.setMode(_vm.prevMode)
                          }
                        }
                      },
                      [_vm._v("Back")]
                    )
                  ])
                ])
              : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-envelope-o" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-lock" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-envelope-o" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-lock" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-lock" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auth-prepend" }, [
      _c("i", { staticClass: "fa fa-fw fa-envelope-o" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }