var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "serp" }, [
    _c("div", { staticClass: "ph0 m-bottom10" }, [
      _c("div", { staticClass: "p-bottom10 m-right10 clearfix" }, [
        _c("div", { staticClass: "serp-stat left" }, [
          _vm.totalCnt !== null
            ? _c("em", [_vm._v("Found: " + _vm._s(_vm.totalCnt))])
            : _vm._e(),
          _vm._v(" "),
          _vm.checkedRows.length > 0
            ? _c("em", { staticClass: "m-left10" }, [
                _vm._v("Checked: " + _vm._s(_vm.checkedRows.length))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "control-box m-right10" }, [
            _c("i", { staticClass: "control-search fa fa-search" }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.app.query.keyword,
                  expression: "app.query.keyword"
                }
              ],
              attrs: { type: "text", placeholder: "Keywords filter" },
              domProps: { value: _vm.app.query.keyword },
              on: {
                change: _vm.onControlChanged,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.app.query, "keyword", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "left m-left10 m-right30 lh30" }, [
            _c("label", { staticClass: "chb" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.app.query.kwExact,
                    expression: "app.query.kwExact"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  "true-value": 1,
                  "false-value": void 0
                },
                domProps: {
                  checked: Array.isArray(_vm.app.query.kwExact)
                    ? _vm._i(_vm.app.query.kwExact, null) > -1
                    : _vm._q(_vm.app.query.kwExact, 1)
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.app.query.kwExact,
                        $$el = $event.target,
                        $$c = $$el.checked ? 1 : void 0
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.app.query,
                              "kwExact",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.app.query,
                              "kwExact",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.app.query, "kwExact", $$c)
                      }
                    },
                    _vm.onControlChanged
                  ]
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "upper" }, [_vm._v("Exact")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "control-box m-right10" },
            [
              _c("label", [_vm._v("Top")]),
              _vm._v(" "),
              _c("base-rich-sel", {
                attrs: {
                  items: _vm.getTopItems(),
                  width: "50",
                  "dd-width": "100",
                  "no-search": "1"
                },
                on: { change: _vm.onControlChanged },
                model: {
                  value: _vm.app.query.top,
                  callback: function($$v) {
                    _vm.$set(_vm.app.query, "top", $$v)
                  },
                  expression: "app.query.top"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "control-box m-right10" },
            [
              _c("label", [_vm._v("Dynamics")]),
              _vm._v(" "),
              _c("base-rich-sel", {
                attrs: {
                  items: _vm.getDynamicsItems(),
                  width: "100",
                  "dd-width": "120",
                  "no-search": "1"
                },
                on: { change: _vm.onControlChanged },
                model: {
                  value: _vm.app.query.dynamics,
                  callback: function($$v) {
                    _vm.$set(_vm.app.query, "dynamics", $$v)
                  },
                  expression: "app.query.dynamics"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "control-box m-right10" },
            [
              _c("label", [_vm._v("Sort")]),
              _vm._v(" "),
              _c("base-rich-sel", {
                attrs: {
                  items: _vm.getSortItems(),
                  width: "120",
                  "dd-width": "120",
                  "no-search": "1"
                },
                on: { change: _vm.onControlChanged },
                model: {
                  value: _vm.app.query.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.app.query, "sort", $$v)
                  },
                  expression: "app.query.sort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "control-btn m-left10",
              on: { click: _vm.showExport }
            },
            [_c("i", { staticClass: "fa fa-fw fa-download" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative box padding0" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading"
                }
              ],
              staticClass: "loading"
            },
            [_vm._m(1)]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.exportShown
              ? _c("div", { staticClass: "serp-overlay" }, [
                  _c("div", { staticClass: "site-report" }, [
                    _c("div", { staticClass: "row xs-8 xl-24" }, [
                      _c("div", { staticClass: "fieldset col" }, [
                        _c("div", { staticClass: "fieldset-label" }, [
                          _c("i", { staticClass: "fa fa-fw fa-file-pdf-o" }),
                          _vm._v(" PDF report")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "site-report-btns" },
                          [
                            _c(
                              "base-btn",
                              {
                                attrs: { loading: _vm.reportLoading.pdf },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.downloadReport("pdf")
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-fw fa-download",
                                  attrs: { slot: "icon" },
                                  slot: "icon"
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Download")])
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col" }, [
                        _c("div", { staticClass: "fieldset-label" }, [
                          _c("i", { staticClass: "fa fa-fw fa-table" }),
                          _vm._v(" CSV report")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "site-report-opts" },
                          _vm._l(_vm.reportCols, function(col) {
                            return _c("div", { key: col }, [
                              _c("label", { staticClass: "chb" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reportKeys.csv[col],
                                      expression: "reportKeys.csv[col]"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.reportKeys.csv[col]
                                    )
                                      ? _vm._i(_vm.reportKeys.csv[col], null) >
                                        -1
                                      : _vm.reportKeys.csv[col]
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.reportKeys.csv[col],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.reportKeys.csv,
                                              col,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.reportKeys.csv,
                                              col,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.reportKeys.csv, col, $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(col))])
                              ])
                            ])
                          })
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "site-report-btns" },
                          [
                            _c(
                              "base-btn",
                              {
                                attrs: { loading: _vm.reportLoading.csv },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.downloadReport("csv")
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-fw fa-download",
                                  attrs: { slot: "icon" },
                                  slot: "icon"
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Download")])
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col" }, [
                        _c("div", { staticClass: "fieldset-label" }, [
                          _c("i", { staticClass: "fa fa-fw fa-file-text-o" }),
                          _vm._v(" TXT report")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "site-report-opts" },
                          _vm._l(_vm.reportCols, function(col) {
                            return _c("div", { key: col }, [
                              _c("label", { staticClass: "chb" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reportKeys.txt[col],
                                      expression: "reportKeys.txt[col]"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.reportKeys.txt[col]
                                    )
                                      ? _vm._i(_vm.reportKeys.txt[col], null) >
                                        -1
                                      : _vm.reportKeys.txt[col]
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.reportKeys.txt[col],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.reportKeys.txt,
                                              col,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.reportKeys.txt,
                                              col,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.reportKeys.txt, col, $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(col))])
                              ])
                            ])
                          })
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "site-report-btns" },
                          [
                            _c(
                              "base-btn",
                              {
                                attrs: { loading: _vm.reportLoading.txt },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.downloadReport("txt")
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-fw fa-download",
                                  attrs: { slot: "icon" },
                                  slot: "icon"
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Download")])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "table",
            {
              staticClass: "serp-tbl",
              style: { opacity: _vm.isLoading ? 0.3 : 1 }
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  [
                    !_vm.simple
                      ? _c("th", { staticClass: "chb-cell" }, [
                          _c("label", { staticClass: "chb" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.allChecked,
                                  expression: "allChecked"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.allChecked)
                                  ? _vm._i(_vm.allChecked, null) > -1
                                  : _vm.allChecked
                              },
                              on: {
                                click: _vm.onAllChbClick,
                                change: function($event) {
                                  var $$a = _vm.allChecked,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.allChecked = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.allChecked = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.allChecked = $$c
                                  }
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("th", { staticClass: "serp-kw-head text-left" }, [
                      _c("div", { staticClass: "head-cell-inner nowrap" }, [
                        _c(
                          "span",
                          {
                            staticClass: "general-upper",
                            on: {
                              click: function($event) {
                                _vm.doSort("kw")
                              }
                            }
                          },
                          [_vm._v("Keyword")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-left" }, [
                      _c("div", { staticClass: "head-cell-inner nowrap" }, [
                        _c(
                          "span",
                          {
                            staticClass: "general-upper",
                            on: {
                              click: function($event) {
                                _vm.doSort("url")
                              }
                            }
                          },
                          [_vm._v("URL")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.realDates, function(date, i) {
                      return _c("th", [
                        _c("div", { staticClass: "head-cell-inner nowrap" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  _vm.doSort(
                                    "pos" +
                                      (i !== _vm.dates.length - 1 ? date : "")
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(date))]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.handledRows, function(row, i) {
                    return [
                      _c(
                        "tr",
                        [
                          !_vm.simple
                            ? _c("td", { staticClass: "chb-cell" }, [
                                _c("label", { staticClass: "chb" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: row.checked,
                                        expression: "row.checked"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(row.checked)
                                        ? _vm._i(row.checked, null) > -1
                                        : row.checked
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.onKeywordChbClick($event, i)
                                      },
                                      change: function($event) {
                                        var $$a = row.checked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                row,
                                                "checked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                row,
                                                "checked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(row, "checked", $$c)
                                        }
                                      }
                                    }
                                  })
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-center serp-num-cell" },
                            [_vm._v(_vm._s(i + 1))]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "span",
                              {
                                staticClass: "serp-kw",
                                on: {
                                  click: function($event) {
                                    _vm.onKeywordClick(row)
                                  }
                                }
                              },
                              [
                                _vm.kw
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getHilitedKw(row.kw)
                                        )
                                      }
                                    })
                                  : [_vm._v(_vm._s(row.kw))]
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "serp-url-cell" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.getPageUrl(row),
                                  target: "_blank",
                                  rel: "noreferrer noorigin"
                                }
                              },
                              [_vm._v(_vm._s("/" + row.page))]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(row.pos, function(pos, j) {
                            return _c("td", { staticClass: "serp-pos-cell" }, [
                              pos && _vm.realDates[j] !== "-"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "serp-pos",
                                      style: _vm.getPosStyle(pos)
                                    },
                                    [_vm._v(_vm._s(pos !== 200 ? pos : 0))]
                                  )
                                : _vm._e()
                            ])
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      row.serpShown
                        ? _c("tr", { staticClass: "kw-serp-row" }, [
                            _c(
                              "td",
                              { attrs: { colspan: "100" } },
                              [
                                row.serpRows
                                  ? _vm._l(row.serpRows, function(row) {
                                      return _c(
                                        "div",
                                        { staticClass: "serp-inner-row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "serp-inner-pos" },
                                            [_vm._v(_vm._s(row.pos) + ".")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "serp-inner-url" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "visitable",
                                                  attrs: {
                                                    href: _vm.getFullUrl(row),
                                                    target: "_blank",
                                                    rel: "noreferrer noorigin"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.https
                                                          ? "https"
                                                          : "http"
                                                      ) +
                                                        "://" +
                                                        _vm._s(
                                                          row.www ? "www." : ""
                                                        )
                                                    )
                                                  ]),
                                                  _c("strong", [
                                                    _vm._v(_vm._s(row.site))
                                                  ]),
                                                  _vm._v("/"),
                                                  _c("span", [
                                                    _vm._v(_vm._s(row.page))
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  : _c("div", [_vm._v("Loading...")])
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("tfoot", [
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoadingMore,
                        expression: "isLoadingMore"
                      }
                    ]
                  },
                  [_vm._m(3)]
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.isLoading && !_vm.isLoadingMore && _vm.isNoMore,
                        expression: "!isLoading && !isLoadingMore && isNoMore"
                      }
                    ]
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "text-center general-upper",
                        attrs: { colspan: "100" }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.rows && _vm.rows.length
                                ? "No more data"
                                : "No data"
                            ) +
                            "\n\t\t\t\t\t\t"
                        )
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "control-btn filled m-left10" }, [
      _c("i", { staticClass: "fa fa-fw fa-plus" }),
      _vm._v(" Add keywords")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-inner" }, [
      _c("i", { staticClass: "fa fa-spinner spin-fast" }),
      _vm._v("\n\t\t\t\t\tLoading...\n\t\t\t\t")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-center serp-num-cell" }, [
      _c("div", { staticClass: "head-cell-inner nowrap" }, [_vm._v("#")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-center", attrs: { colspan: "100" } }, [
      _c("i", { staticClass: "fa fa-spinner spin-fast" }),
      _vm._v(" "),
      _c("span", { staticClass: "general-upper" }, [_vm._v("Loading...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }