<template>
	<div class="auth-box">
		<div class="auth-text">
			<template v-if="mode === 'forgot'">
				Enter your e-mail, and you will get instructions to restore access to your account.
			</template>
		</div>
		<form class="auth-form" @submit.prevent="onSubmit">
			<div v-if="mode === 'login'">
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-envelope-o"></i>
					</div><input type="text" placeholder="E-mail" name="email" v-model="email" :disabled="isLoading">
				</div>
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-lock"></i>
					</div><input type="password" placeholder="Password" name="password" v-model="password" :disabled="isLoading">
				</div>
				<div class="auth-error-box" v-show="loginErrors.length">
					<div class="auth-err" v-for="(err, index) in loginErrors" :key="index">{{ err }}</div>
				</div>
				<div class="auth-btn-row text-center">
					<base-btn submit :loading="isLoading">
						<i v-show="!isLoading" class="fa fa-fw fa-sign-in"></i>
						Login
					</base-btn>
				</div>
				<div class="auth-forgot-row">
					<span class="link" @click="setMode('forgot')">Forgot password?</span>
				</div>
				<soc-login></soc-login>
				<div class="auth-alt-option">
					Have no account? <span class="link" @click="setMode('register')">Register</span>
				</div>
			</div>

			<div v-else-if="mode === 'register'">
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-user"></i>
					</div><input type="text" placeholder="Name" name="username" v-model="regUsername" :disabled="isLoading">
				</div>
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-envelope-o"></i>
					</div><input type="text" placeholder="E-mail" name="email" v-model="regEmail" :disabled="isLoading">
				</div>
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-lock"></i>
					</div><input type="password" placeholder="Password" name="password" v-model="regPassword" :disabled="isLoading">
				</div>
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-lock"></i>
					</div><input type="password" placeholder="Confirm password" name="configm" v-model="regConfirm" :disabled="isLoading">
				</div>
				<div class="auth-error-box" v-show="loginErrors.length">
					<div class="auth-err" v-for="(err, index) in loginErrors" :key="index">{{ err }}</div>
				</div>
				<div class="auth-btn-row text-center">
					<base-btn submit :loading="isLoading">
						<i v-show="!isLoading" class="fa fa-fw fa-plus"></i>
						Create account
					</base-btn>
				</div>
				<soc-login></soc-login>
				<div class="auth-alt-option">
					Already have an account? <span class="link" @click="setMode('login')">Login</span>
				</div>
			</div>

			<div v-else-if="mode === 'forgot'">
				<div class="auth-input-row">
					<div class="auth-prepend">
						<i class="fa fa-fw fa-envelope-o"></i>
					</div><input type="text" placeholder="E-mail" name="email" v-model="remindEmail" :disabled="isLoading">
				</div>
				<div class="auth-btn-row text-center">
					<base-btn submit :loading="isLoading">
						<i v-show="!isLoading" class="fa fa-fw fa-send"></i>
						Send
					</base-btn>
				</div>
				<div class="auth-back-row">
					<span class="link" @click="setMode(prevMode)">Back</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	let socLoginHtml = `
	<div class="auth-3party">
		Login with
		<span class="link" @click="loginOAuth('github')">
			<i class="fa fa-github github-color"></i>
			<span>GitHub</span>
		</span>
		<span class="link" @click="loginOAuth('facebook')">
			<i class="fa fa-facebook-official facebook-color"></i>
			<span class="gh-color">Facebook</span>
		</span>
		<span class="link" @click="loginOAuth('google')">
			<i class="fa fa-google google-color"></i>
			<span>Google</span>
		</span>
	</div>
`;
	let socLogin = {
		template: socLoginHtml,
		methods: {
			loginOAuth(provider) {
				let url = '/auth/' + provider;
				let width = 700;
				let height = 500;

				if (provider === 'facebook') {
					width = 600;
					height = 500;
				} else if (provider === 'github') {
					height = 600;
				}

				let l = window.screenX + (window.outerWidth - width) / 2;
				let t = window.screenY + (window.outerHeight - height) / 2;
				let winProps = 'width='+width+',height='+height+',left='+l+',top='+t+',status=no,resizable=yes,toolbar=no,menubar=no,scrollbars=yes';
				console.log(url);
				let win = window.open(url, provider, winProps);

				let checkClosed = () => {
					if (!win.closed) return setTimeout(checkClosed, 100);

					this.$parent.$emit('authstatuschanged', {type: provider});
				};

				checkClosed();
			}
		}
	};

	export default {
		props: [],
		components: {
			'soc-login': socLogin
		},
		data() {
			return {
				mode: 'login',
				prevMode: null,

				email: '',
				password: '',

				regUsername: '',
				regEmail: '',
				regPassword: '',
				regConfirm: '',

				remindEmail: '',

				isLoading: false,
				loginErrors: [],

				defaultLoginError: 'Wrong login or password',
				defaultRegisterError: 'An error occurred'
			};
		},
		methods: {
			setMode(mode) {
				this.loginErrors = [];
				this.prevMode = this.mode;
				this.mode = mode;
				this.$emit('modechanged', {mode});
			},
			onSubmit() {
				if (this.mode === 'login') {
					this.tryLogin();
				} else if (this.mode === 'register') {
					this.tryRegister();
				} else if (this.mode === 'forgot') {
					this.alert('Not working, yet');
				}
			},
			async tryLogin() {
				if (this.password.length < 6) {
					this.loginErrors = [this.defaultLoginError];
					return;
				}

				this.loginErrors = [];

				this.isLoading = true;
				let success = await this.post('User.login', this.email, this.password);
				this.isLoading = false;

				if (success) {
					this.$emit('authstatuschanged', {type: 'login'});
				} else {
					this.loginErrors = [this.defaultLoginError];
				}
			},
			async tryRegister() {
				if (!this.regEmail || !this.regUsername || !this.regPassword || !this.regConfirm) {
					this.loginErrors = ['Please fill all the fields'];
					return;
				}

				this.loginErrors = [];
				this.isLoading = true;

				let res = await this.post('User.register', {
					email: this.regEmail,
					name: this.regUsername,
					password: this.regPassword,
					confirm: this.regConfirm
				});

				this.isLoading = false;

				if (res.success) {
					this.$emit('authstatuschanged', {type: 'login'});
				} else {
					this.loginErrors = res.errors || [this.defaultRegisterError];
				}
			}
		}
	}
</script>

<style lang="less">
	@import "../styles/mixin";

	.auth-box {
		padding: 30px 0 40px;

		.auth-form {
			@formWidth: 300px;

			margin: 0 auto 0;
			width: @formWidth + 2px;

			.auth-input-row {
				position: relative;
				margin: 3px 0;
				height: 30px;
				overflow: hidden;

				.auth-prepend {
					float: left;
					width: 30px;
					height: 100%;
					line-height: 30px;
					font-size: 13px;
					text-align: center;
					border: 1px solid #bfcbd9;
					border-right: none;
					border-radius: 4px 0 0 4px;
					color: #97a8be;
					background: #fbfdff;
				}

				input {
					float: left;
					padding: 0 10px 0 10px;
					width: @formWidth - 30px;
					height: 100%;
					line-height: 30px;
					border: 1px solid #bfcbd9;
					border-radius: 0 4px 4px 0;
					outline: none;
					.transition(all 0.2s);

					&:hover {
						border-color: #a0b0c5;
					}
					&:focus {
						border-color: #5197e8;
					}
				}
			}

			.auth-btn-row {
				padding: 5px 0 0;

				button[type="submit"] {
					padding: 7px 11px 7px 8px;
				}
			}
		}
		.auth-error-box {
			padding: 5px 0 10px;
			text-align: center;

			.auth-err {
				font-size: 13px;
				color: #f00;
			}
		}
		.auth-3party {
			padding: 20px 0 0 0;
			text-align: center;

			span.link {
				margin-left: 5px;

				&:hover {
					text-decoration: none !important;

					span {
						text-decoration: underline;
					}
				}
			}
		}
		.auth-forgot-row {
			padding: 7px 0 0;
			text-align: center;
		}
		.auth-alt-option {
			padding: 20px 0 0;
			text-align: center;
		}
		.auth-text {
			padding: 0 30px 20px;
		}
		.auth-back-row {
			padding: 30px 0 0;
			text-align: center;
		}
	}
</style>