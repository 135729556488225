var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-tools" },
    _vm._l(_vm.app.tools, function(tool) {
      return _c("a", { attrs: { href: _vm.getToolUrl(tool.key) } }, [
        _vm._v(_vm._s(tool.name))
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }