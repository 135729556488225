<template>
	<button
		:type="submit != null ? 'submit' : 'button'"
		:class="getClasses()"
		:disabled="disabled || loading"
		@click="onClick"
		@mousedown.prevent
	>
		<span v-if="!loading">
			<slot name="icon"></slot>
		</span>
		<span><i v-if="loading" class="fa fa-fw fa-spinner spin-fast"></i></span>
		<slot></slot>
	</button>
</template>

<script>
	export default {
		props: ['type', 'size', 'hidden', 'disabled', 'loading', 'submit'],
		data() {
			return {};
		},
		methods: {
			getClasses() {
				let TYPES = ['primary', 'orange', 'red'];
				let SIZES = ['normal', 'small', 'big'];

				let type = TYPES.indexOf(this.type) !== -1 ? this.type : TYPES[0];
				let size = SIZES.indexOf(this.size) !== -1 ? this.size : SIZES[0];

				let classes = ['btn', 'btn-' + type, 'btn-' + size, 'nowrap'];
				if (this.hidden) {
					classes.push('btn-hidden');
				}

				return classes.join(' ');
			},
			onClick(e) {
				this.$emit('click', e);
			}
		}
	}
</script>

<style lang="less">
	@import "../styles/mixin.less";

	.btn {
		display: inline-block;
		color: #fff;
		text-transform: uppercase;
		font-weight: 500;
		cursor: pointer;
		border: none;
		border-radius: 1px;
		user-select: none;
		.text-shadow(0 1px 1px rgba(0, 0, 0, 0.4));
		.transition(all .1s);

		&.btn-hidden {
			visibility: hidden;
			opacity: 0;
		}

		&.btn-big {
			padding: 10px 16px 10px 13px;
			font-size: 14px;
		}
		&.btn-normal {
			padding: 6px 8px;
			font-size: 11px;
		}
		&.btn-small {
			padding: 5px 5px;
			font-size: 10px;
		}

		&.btn-primary {
			@bg: lighten(@primColor, 10);

			background-color: @bg;

			&:not([disabled]) {
				&:hover {
					background-color: mix(@bg, #fff, 80);
				}
				&:active {
					background-color: mix(@bg, #000, 80);
				}
			}

			&[disabled] {
				cursor: default;
				background-color: mix(@bg, #fff, 50);
			}
		}

		&.btn-orange {
			@bg: #e8910c;

			background-color: @bg;

			&:not([disabled]) {
				&:hover {
					background-color: mix(@bg, #fff, 80);
				}
				&:active {
					background-color: mix(@bg, #000, 80);
				}
			}

			&[disabled] {
				cursor: default;
				background-color: mix(@bg, #fff, 50);
			}
		}

		&.btn-red {
			@bg: #ff5a5a;

			background-color: @bg;

			&:not([disabled]) {
				&:hover {
					background-color: mix(@bg, #fff, 80);
				}
				&:active {
					background-color: mix(@bg, #000, 80);
				}
			}

			&[disabled] {
				cursor: default;
				background-color: mix(@bg, #fff, 50);
			}
		}
	}
</style>