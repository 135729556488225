<template>
	<a v-if="isSubDomain" :href="url"><slot></slot></a>
	<router-link v-else :to="url"><slot></slot></router-link>
</template>

<script>
	export default {
		props: ['href'],
		computed: {
			isSubDomain() {
				return location.host !== this.app.rootDomain;
			},
			url() {
				if (!this.isSubDomain) return this.href;
				return 'https://' + this.app.rootDomain + this.href;
			}
		}
	}
</script>