import methods from './methods';
import computed from './computed';
import filters from './filters';
import directives from './directives';

export default {
	data() {
		return {
			winWidth: methods.getWinWidth(),
			winHeight: methods.getWinHeight()
		};
	},
	methods,
	computed,
	filters,
	directives
};