<template>
	<span>
		<img
			src="/img/pixel.png"
			v-if="cc"
			:class="'flag flag-' + geoNormalized"
			v-title="!noTitle ? countryName : null"
		/>
		<span v-else class="empty-flag"></span>
	</span>
</template>

<script>
	export default {
		props: ['cc', 'no-title'],
		computed: {
			countryName() {
				const cc = String(this.cc || '').toUpperCase();
				return this.app.geoMap[cc] || cc;
			},
			geoNormalized() {
				return String(this.cc || '').toLowerCase();
			}
		}
	}
</script>

<style lang="less">
	.empty-flag {
		display: inline-block;
		width: 16px;
		height: 11px;
		background: #ddd;
	}
</style>