var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-sidebar", class: _vm.minimized ? "minimized" : "" },
    [
      _c(
        "ul",
        { staticClass: "sidebar-prim-list" },
        [
          _vm._l(_vm.pages, function(page) {
            return _vm.loggedIn || !page.requireAuth
              ? [
                  _c(
                    "li",
                    { staticClass: "sidebar-prim-item" },
                    [
                      _c(
                        "base-link",
                        {
                          directives: [
                            {
                              name: "title",
                              rawName: "v-title.right",
                              value: _vm.minimized ? page.label : "",
                              expression: "minimized ? page.label : ''",
                              modifiers: { right: true }
                            }
                          ],
                          attrs: { href: page.url || "" },
                          nativeOn: {
                            click: function($event) {
                              _vm.onPageClick($event, page)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "sidebar-item-icon",
                            class: page.cls
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(page.label))]),
                          _vm._v(" "),
                          page.sub && !_vm.minimized
                            ? _c("i", {
                                staticClass:
                                  "sidebar-item-arrow fa fa-chevron-down"
                              })
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      page.subShown
                        ? _c(
                            "ul",
                            { staticClass: "sidebar-sec-list" },
                            _vm._l(page.sub, function(subPage, j) {
                              return _c(
                                "li",
                                { staticClass: "sidebar-sec-item" },
                                [
                                  _c(
                                    "base-link",
                                    { attrs: { href: subPage.url } },
                                    [
                                      _c("span"),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(subPage.label) +
                                          "\n\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              : _vm._e()
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar-bottom", on: { click: _vm.toggleSidebar } },
        [_c("i", { staticClass: "fa fa-fw fa-chevron-left" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }