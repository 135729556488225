var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color-picker" }, [
    _c("div", { staticClass: "field" }, [
      _c("canvas", {
        ref: "field",
        attrs: { width: "200", height: "200" },
        on: {
          mousedown: function($event) {
            _vm.fieldDrug = 1
          },
          mouseup: function($event) {
            _vm.fieldDrug = 0
          },
          mousemove: _vm.pickBrightness
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "picker",
        style:
          "top: " +
          (_vm.field.y - 7.5) +
          "px; left: " +
          (_vm.field.x - 7.5) +
          "px;"
      })
    ]),
    _vm._v(" "),
    _c("canvas", {
      ref: "stripe",
      staticClass: "stripe",
      attrs: { width: "50", height: "200" },
      on: {
        mousedown: function($event) {
          _vm.stripeDrug = 1
        },
        mouseup: function($event) {
          _vm.stripeDrug = 0
        },
        mousemove: _vm.pickColor
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }