var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-popup" }, [
    _c(
      "a",
      {
        staticClass: "video-close",
        on: {
          click: function($event) {
            _vm.close()
          }
        }
      },
      [_c("img", { attrs: { src: "/img/testimonials/close.svg", alt: "" } })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "video-container" }, [
      _c("div", { staticClass: "video-title" }, [_vm._v("Testimonials")]),
      _vm._v(" "),
      _c("iframe", {
        attrs: {
          src: _vm.url,
          frameborder: "0",
          id: "popup1_redirect",
          allow: "autoplay; encrypted-media"
        }
      }),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "video-btn-request", attrs: { "data-ll": "5361" } },
      [
        _vm._v("Request a Quote\t\t\t\t\t"),
        _c("i", { staticClass: "zmdi zmdi-mail-send" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }