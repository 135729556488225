var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        class: ["popup", _vm.cls, _vm.dark ? "popup-dark" : ""],
        on: { click: _vm.onClick }
      },
      [
        _c(
          "div",
          { ref: "popupBox", staticClass: "popup-panel", style: _vm.style },
          [
            _c(
              "div",
              {
                ref: "popupHeader",
                staticClass: "popup-header",
                on: {
                  mousedown: _vm.onHeaderMouseDown,
                  touchstart: _vm.onHeaderTouchStart
                }
              },
              [
                _c("div", { staticClass: "left" }, [_vm._t("header")], 2),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c("span", {
                    staticClass: "popup-close fa fa-close",
                    on: {
                      click: function($event) {
                        _vm.close()
                      }
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "popup-content-outer" }, [
              _c(
                "div",
                {
                  directives: [
                    { name: "block-scroll", rawName: "v-block-scroll" }
                  ],
                  ref: "content",
                  class: [
                    "popup-content",
                    { "popup-scrollable": !_vm.noScroll }
                  ],
                  style: _vm.contentStyle
                },
                [_vm._t("default", [_vm._v("empty popup")])],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "popup-footer" },
              [
                _vm._t("footer"),
                _vm._v(" "),
                _vm.resize
                  ? _c("div", {
                      staticClass: "popup-resizer-box",
                      on: { mousedown: _vm.onResizeMouseDown }
                    })
                  : _vm._e()
              ],
              2
            )
          ]
        ),
        _vm._v(" "),
        _vm.overfix ? _c("div", { staticClass: "overlaying-fix" }) : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }