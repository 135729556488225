<template>
	<div>Permission denied. Please <span class="link" @click="authorize">authorize</span></div>
</template>

<script>
	export default {
		methods: {
			authorize() {
				this.app.authForm = {};
			}
		}
	}
</script>