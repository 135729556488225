<template>
	<div class="base-select" :class="getClasses()" :data-val="value" v-mousedown-outside="hideItems">
		<i class="base-select__arrow fa fa-caret-down"></i>
		<div v-if="valHtml" class="base-select__val-html" v-html="valHtml"></div>
		<div class="clearfix">
			<div class="base-select__label" v-if="label">{{ label }}</div>
			<input
				type="text"
				class="base-select__val-tf"
				:value="!valHtml ? tfText : ''"
				:placeholder="placeholder"
				@focus="showItems"
				readonly
			>
		</div>
		<div
			ref="suggestBox"
			:class="['base-select__dd', 'base-select__dd-' + ddPos, 'base-select__dd-' + ddPosY]"
			v-show="suggestionsShown"
		>
			<div class="base-select__search-box">
				<input
					type="text"
					class="base-select__search-tf"
					v-model="searchVal"
					ref="searchTf"
					@keydown.down.prevent="moveDown"
					@keydown.up.prevent="moveUp"
					@keydown.page-up.prevent="moveUpFast"
					@keydown.page-down.prevent="moveDownFast"
					@keydown.enter.prevent="choose"
					@keydown.esc="hideItems"
					:placeholder="searchPlaceholder || 'Search'"
				>
			</div>
			<div class="base-select__dd-items" ref="itemsBox" v-block-scroll>
				<div v-for="i in 2">
					<template v-for="(item, index) in filteredItems">
						<div
							v-if="i === 1 && stickyCheckedKeysMap[item.key] || i === 2 && !stickyCheckedKeysMap[item.key]"
							:class="['base-select__dd-item', {'base-select__hilited': (index === hiliteIndex)}]"
							:ref="'item' + item.key"
							:title="item.title"
							@mouseover="hiliteIndex = index"
							@click="choose"
						>
							<base-checkbox
								v-if="multiple"
								:checked="!!checkedKeysMap[item.key]"
							></base-checkbox>
							<span v-if="item.html" v-html="item.html"></span>
							<span v-else>{{ item.val || '&nbsp;' }}</span>
						</div>
					</template>
				</div>
			</div>
			<div class="base-select__multiple-actions" v-if="multiple">
				<base-btn @click.native="checkAll">Check all</base-btn>
				<base-btn @click.native="apply">Apply</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				suggestionsShown: false,
				searchVal: '',
				hiliteIndex: 0,
				movingFastStep: 10,
				ddPos: null,
				ddPosY: null,
				valHtml: null,
				resItems: [],
				time: new Date().getTime(),
				checkedKeysMap: {},
				stickyCheckedKeysMap: {}
			};
		},
		props: ['value', 'items', 'label', 'placeholder', 'search-placeholder', 'multiple', 'searchable'],
		computed: {
			tfText() {
				let items = this.getItemsByValue();
				if (!items || !items.length) return this.defaultText || this.value || '';
				return items.map(item => item.displayVal || item.val).join(', ');
			},
			filteredItems() {
				if (!this.searchable || !this.resItems || !this.resItems.length) return this.resItems;
				return this.resItems.filter(this.checkItemMatch);
			},
			sortedItems() {
				if (Object.keys(this.checkedKeysMap).length === 0) return this.filteredItems;

				let len = this.filteredItems;
				return this.filteredItems.sort((a, b) => {
					let idxA = this.filteredItems.indexOf(a);
					if (this.checkedKeysMap[a.key]) {
						idxA -= len;
					}
					let idxB = this.filteredItems.indexOf(b);
					if (this.checkedKeysMap[b.key]) {
						idxB -= len;
					}
					if (idxA > idxB) return 1;
					if (idxA < idxB) return -1;
					return 0;
				});
			}
		},
		methods: {
			getClasses() {
				let classes = [];
				if (!this.searchable) {
					classes.push('base-select__no-search');
				}
				return classes;
			},
			showItems() {
				this.stickyCheckedKeysMap = {...this.checkedKeysMap};

				if (!this.items && this.itemsUrl) {
					this.resItems = [];
				}
				this.suggestionsShown = true;
				this.ddPos = 'left';
				this.ddPosY = 'bottom';

				let items = this.getItemsByValue();
				let item = items[0];
				let index = this.filteredItems.indexOf(item);
				if (index === -1) {
					index = 0;
				}
				this.hiliteIndex = index;

				this.$nextTick(() => {
					this.tryScroll();
					this.$refs.searchTf && this.$refs.searchTf.focus();

					let rect = this.$refs.suggestBox.getBoundingClientRect();

					let overlayX = rect.right - window.innerWidth + 50;
					this.ddPos = overlayX < 0 ? 'left' : 'right';

					let overlayY = rect.bottom - window.innerHeight;
					//this.ddPosY = overlayY < 0 ? 'bottom' : 'top';
				});
			},
			hideItems() {
				this.suggestionsShown = false;
				this.searchVal = '';
			},
			getItemsByValue() {
				if (!this.resItems) return [];
				if (this.multiple) {
					let keys = this.value.split(',');
					return this.resItems.filter(item => keys.indexOf(item.key) !== -1);
				}

				return this.resItems.filter(item => item.key === this.value);
			},
			getHilitedItem() {
				return this.filteredItems[this.hiliteIndex];
			},
			checkItemMatch(item) {
				if (!this.searchVal) return true;

				if (!item) return false;

				let regex = new RegExp(this.escapeRegex(this.searchVal), 'i');
				let compareVal = item.sVal || item.key + '*' + item.val;
				return regex.test(compareVal)
			},
			moveUp(opt) {
				if (--this.hiliteIndex < 0) {
					this.hiliteIndex = this.filteredItems.length - 1;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			moveDown(opt) {
				if (++this.hiliteIndex >= this.filteredItems.length) {
					this.hiliteIndex = 0;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			moveUpFast(opt) {
				this.hiliteIndex -= this.movingFastStep;
				if (this.hiliteIndex < 0) {
					this.hiliteIndex = 0;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			moveDownFast(opt) {
				this.hiliteIndex += this.movingFastStep;
				if (this.hiliteIndex >= this.filteredItems.length) {
					this.hiliteIndex = this.filteredItems.length - 1;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			check(e, item) {
				if (e.target.checked) {
					this.$set(this.checkedKeysMap, item.key, true);
				} else {
					this.$delete(this.checkedKeysMap, item.key);
				}
				this.tryFocusSearch();
			},
			choose() {
				if (this.multiple) {
					let item = this.filteredItems[this.hiliteIndex];
					let cur = this.checkedKeysMap[item.key];
					if (!cur) {
						this.$set(this.checkedKeysMap, item.key, item);
					} else {
						this.$delete(this.checkedKeysMap, item.key);
					}
					return;
				}

				let item = this.filteredItems[this.hiliteIndex];
				if (!item) return;

				this.hideItems();

				this.valHtml = item.valHtml;

				this.$emit('input', item.key);
				this.$emit('change', {isTrusted: true});
			},
			checkAll() {
				let uncheckedExist = this.filteredItems.filter(item => !this.checkedKeysMap[item.key]).length;

				this.filteredItems.forEach(item => {
					if (uncheckedExist) {
						this.$set(this.checkedKeysMap, item.key, item);
					} else {
						this.$delete(this.checkedKeysMap, item.key);
					}
				});

				this.tryFocusSearch();
			},
			apply() {
				let val = Object.keys(this.checkedKeysMap).join(',');
				this.$emit('input', val);
				this.$emit('change', {isTrusted: true});
				this.hideItems();
			},
			tryScroll() {
				let item = this.filteredItems[this.hiliteIndex];
				if (!item) return;

				let itemNodes = this.$refs['item' + item.key];
				if (!itemNodes || !itemNodes[0]) return;

				let itemNode = itemNodes[0];
				let itemPos = itemNode.getBoundingClientRect();

				let itemsBox = this.$refs.itemsBox;
				let itemsBoxPos = itemsBox.getBoundingClientRect();

				let extraDistBottom = itemPos.bottom - itemsBoxPos.bottom;
				if (extraDistBottom > 0) {
					itemsBox.scrollTop += extraDistBottom;
				}

				let extraDistTop = itemsBoxPos.top - itemPos.top;
				if (extraDistTop > 0) {
					itemsBox.scrollTop -= extraDistTop;
				}
			},
			tryFocusSearch() {
				if (this.$refs.searchTf && this.app.win.width >= this.app.win.LG) {
					this.$refs.searchTf.focus();
				}
			},
			updateResItems() {
				this.resItems = this.items ? [...this.items] : [];
			},
			updateValHtml() {
				this.resItems.filter(item => item.key === this.value).forEach(item => {
					this.valHtml = item.valHtml;
				});
			},
			updateCheckboxes() {
				this.checkedKeysMap = {};
				this.value.split(',').forEach(key => {
					if (!key) return;
					this.$set(this.checkedKeysMap, key, true);
				});
			}
		},
		watch: {
			searchVal() {
				if (this.searchable) {
					this.hiliteIndex = 0;
				}
			},
			items() {
				this.updateResItems();
			},
			value() {
				this.updateCheckboxes();
			}
		},
		created() {
			this.updateCheckboxes();
			this.updateResItems();
			this.updateValHtml();
		}
	}
</script>

<style lang="less">
	@import "../styles/mixin";

	.base-select {
		display: inline-block;
		position: relative;
		background: #fff;
		vertical-align: middle;

		&.base-select__no-search .base-select__search-box {
			position: absolute;
			margin-top: -50px;
		}

		* {
			text-align: left;

			&:not(.fa):not(.zmdi) {
				.open-sans;
			}
		}

		.base-select__label {
			float: left;
		}

		select {
			padding-top: 0 !important;
			width: 100%;
		}

		.base-select__arrow {
			position: absolute;
			top: 6px;
			right: 6px;
			font-size: 10px;
			background: transparent;
		}

		.base-select__val-html {
			position: absolute;
			padding: 0 0 0 5px;
			line-height: 23px;
		}

		.base-select__val-tf {
			float: left;
			position: relative;
			padding-right: 15px !important;
			width: 100px;
			background: transparent !important;
		}

		.base-select__search-box {
			padding: 4px;
		}

		.base-select__dd {
			position: absolute;
			margin-top: -1px;
			left: 0;
			min-width: 100%;
			background: #fff;
			border: 1px solid #9a9a9a;
			z-index: 1000;
			overflow: hidden;

			&.base-select__dd-right {
				right: 0;
			}

			&.base-select__dd-top {
				bottom: 22px;
			}

			.base-select__search-tf {
				width: 100% !important;
			}

			.base-select__dd-items {
				max-height: 300px;
				overflow-y: scroll;
				.scrollbar(3px);

				.base-select__dd-item {
					padding: 0 5px 0 4px;
					cursor: pointer;
					white-space: nowrap;

					&.base-select__hilited {
						color: #fff;
						background: @primColor;
					}
				}

				.base-select__dd-sep {
					height: 6px;
				}
			}

			.base-select__alter-items {
				display: block;
				text-align: center;
				margin: 5px;
			}
		}
	}
</style>