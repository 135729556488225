<template>
	<transition name="fade">
		<div :class="['popup', cls, dark ? 'popup-dark' : '']" @click="onClick">
			<div class="popup-panel" :style="style" ref="popupBox">
				<div class="popup-header" @mousedown="onHeaderMouseDown" @touchstart="onHeaderTouchStart"
					 ref="popupHeader">
					<div class="left">
						<slot name="header"></slot>
					</div>
					<div class="right">
						<span @click="close()" class="popup-close fa fa-close"></span>
					</div>
				</div>
				<div class="popup-content-outer">
					<div
						ref="content"
						:class="['popup-content', {'popup-scrollable': !noScroll}]"
						:style="contentStyle"
						v-block-scroll
					>
						<slot>empty popup</slot>
					</div>
				</div>
				<div class="popup-footer">
					<slot name="footer"></slot>
					<div v-if="resize" class="popup-resizer-box" @mousedown="onResizeMouseDown"></div>
				</div>
			</div>
			<div class="overlaying-fix" v-if="overfix"></div>
		</div>
	</transition>
</template>

<script>
	export default {
		props: [
			'cls',
			'width',
			'content-min-height',
			'content-max-height',
			'scroll-to-bottom',
			'hard-close',
			'no-scroll',
			'resize',
			'dark'
		],
		data() {
			return {
				dndStartX: 0,
				dndStartY: 0,
				offsetLeft: 0,
				offsetTop: 0,
				overfix : false,
				widthPopup : 0,
				heightPopup : 0
			};
		},
		computed: {
			style() {
				let style = {
					left: this.offsetLeft + 'px',
					top: this.offsetTop + 'px'
				};
				if (this.width) {
					style.width = isNaN(this.width) ? this.width : (this.width + 'px');
					if (this.widthPopup > 0) style.width = this.widthPopup + 'px';
					if (this.heightPopup > 0) this.$refs.content.style.minHeight = this.heightPopup + 'px';
					if (this.heightPopup > 0) this.$refs.content.style.maxHeight = this.heightPopup + 'px';
				}
				return style;
			},
			contentStyle() {
				let style = {};
				if (this.contentMinHeight) {
					style.minHeight = isNaN(this.contentMinHeight) ? this.contentMinHeight : this.contentMinHeight + 'px';
				}
				if (this.contentMaxHeight) {
					style.maxHeight = isNaN(this.contentMaxHeight) ? this.contentMaxHeight : this.contentMaxHeight + 'px';
				}
				return style;
			}
		},
		created() {
			if (this.scrollToBottom == 1) {
				this.$nextTick(() => {
					this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
				});
			}
		},
		methods: {
			onClick(e) {
				if (this.hardClose) return;
				if (e.target !== this.$el) return;
				this.close();
			},

			onHeaderMouseDown(e) {
				if (e.target !== e.currentTarget) return;

				e.preventDefault();

				this.dndStartX = e.clientX - this.offsetLeft;
				this.dndStartY = e.clientY - this.offsetTop;

				document.addEventListener('mousemove', this.onDocMouseMove);
				document.addEventListener('mouseup', this.unbindDocMouseUp);

				this.unbindDocTouchEnd();
			},
			onDocMouseMove(e) {
				this.offsetLeft = e.clientX - this.dndStartX;
				this.offsetTop = e.clientY - this.dndStartY;
			},
			unbindDocMouseUp(e) {
				document.removeEventListener('mousemove', this.onDocMouseMove);
				document.removeEventListener('mouseup', this.unbindDocMouseUp);
			},

			onResizeMouseDown(e) {
				if (!this.resize) return;
				this.overfix = true;

				e.preventDefault();

				document.addEventListener('mousemove', this.onResizeMouseMove);
				document.addEventListener('mouseup', this.unbindResizeMouseUp);

				this.$refs.popupBox.style.left = this.$refs.popupBox.offsetLeft + 'px';
				this.$refs.popupBox.style.margin = 0;
			},
			onResizeMouseMove(e) {
				this.widthPopup = e.clientX - this.$refs.popupBox.offsetLeft;
				this.heightPopup = e.clientY - this.$refs.popupBox.offsetTop - this.$refs.popupHeader.offsetHeight;
			},
			unbindResizeMouseUp(e) {
				this.offsetLeft = this.$refs.popupBox.offsetLeft;//added for preventing 'jump' while dragging element again
				this.overfix = false;

				document.removeEventListener('mousemove', this.onResizeMouseMove);
				document.removeEventListener('mouseup', this.unbindResizeMouseUp);
			},

			onHeaderTouchStart(e) {
				if (e.target !== e.currentTarget) return;
				if (!e.touches || !e.touches[0]) return;

				e.preventDefault();

				let touch = e.touches[0];

				this.dndStartY = touch.clientY - this.offsetTop;

				document.addEventListener('touchmove', this.onDocTouchMove);
				document.addEventListener('touchend', this.unbindDocTouchEnd);

				this.unbindDocMouseUp();
			},
			onDocTouchMove(e) {
				if (!e.touches || !e.touches[0]) return;

				let touch = e.touches[0];
				this.offsetTop = touch.clientY - this.dndStartY;
			},
			unbindDocTouchEnd(e) {
				document.removeEventListener('touchmove', this.onDocTouchMove);
				document.removeEventListener('touchend', this.unbindDocTouchEnd);
			},

			close(eventSource) {
				this.$emit('close', eventSource);
			}
		}
	}
</script>

<style lang="less">
	@import "../styles/mixin.less";

	.fade-enter-active, .fade-leave-active {
		.popup-panel {
			.transition(transform .2s);
		}
	}
	.fade-enter, .fade-leave-to {
		.popup-panel {
			transform: translate(0, -50px);
		}
	}

	.popup {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		padding: 5px 0 0;
		background: rgba(250, 250, 250, 0.7);
		z-index: 999999;

		&.popup-dark {
			background: rgba(55, 55, 55, 0.7);
		}

		@media (min-height: 470px) {
			padding: 25px 0 0;
		}

		@media (min-height: 600px) {
			padding: 50px 0 0;
		}

		.popup-panel {
			position: relative;
			margin: 0 auto;
			width: 400px;
			max-width: 98%;
			font-size: 14px;
			background: #fff;
			border-radius: 3px;
			.box-shadow(0 10px 40px rgba(0,0,0,0.4));

			.popup-header {
				position: relative;
				padding: 0 9px 0 9px;
				height: 30px;
				line-height: 30px;
				font-weight: 500;
				font-size: 13px;
				text-transform: uppercase;
				color: #fff;
				background: @primColor;
				border-radius: 3px 3px 0 0;
				.main-font(500);
				.text-shadow(0 1px 1px rgba(0, 0, 0, 0.3));

				.popup-close {
					position: relative;
					top: -1px;
					color: #fff;
					cursor: pointer;
					opacity: 0.8;

					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
			}
			.popup-content-outer {
				width: 100%;
				height: 100%;
			}
			.popup-footer {
				position: relative;
				padding: 5px 3px 4px;
				.popup-resizer-box {
					position: absolute;
					width: 14px;
					height: 14px;
					right: 0;
					bottom: 0;
					background: radial-gradient(circle at top left, transparent 68%, @primColor 71%);
					cursor: nwse-resize;
					&:hover {
						background: radial-gradient(circle at top left, transparent 65%, @primColor 90%);
					}
				}
			}
		}
		.overlaying-fix {
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			cursor: nwse-resize;
			z-index: 1999999;
		}
		.popup-content {
			padding: 3px;
			max-height: 100%;
			.scrollbar(@track-color: transparent);

			&.popup-scrollable {
				overflow-y: scroll;
			}
		}
	}
</style>