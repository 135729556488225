var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart" },
    [
      _vm.serverTime && !_vm.type && _vm.withStat
        ? _c("div", { staticClass: "chart-info-right" }, [
            _c("em", [
              _vm._v("API time: " + _vm._s(_vm._f("toFixed")(_vm.apiTime, 5)))
            ]),
            _vm._v(" "),
            _c("em", [
              _vm._v(
                "API request: " + _vm._s(_vm._f("toFixed")(_vm.apiRequest, 5))
              )
            ]),
            _vm._v(" "),
            _c("em", [
              _vm._v(
                "Server time: " + _vm._s(_vm._f("toFixed")(_vm.serverTime, 5))
              )
            ]),
            _vm._v(" "),
            _c("em", [
              _vm._v(
                "Total time: " + _vm._s(_vm._f("toFixed")(_vm.totalTime, 5))
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c("div", { staticClass: "chart-loading" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.series && !_vm.isLoading
        ? _c("highcharts", { attrs: { options: _vm.getChartOpts() } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-spinner spin-fast center" }),
      _vm._v(" "),
      _c("span", { staticClass: "general-upper" }, [_vm._v("Chart loading...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }