import moment from 'moment';
import md5 from  'js-md5';
import methods from './methods';

export default {
	capitalize(val) {
		if (!val) return '';
		val = val.toString();
		return val.charAt(0).toUpperCase() + val.slice(1);
	},
	upper(val) {
		return String(val).toUpperCase();
	},
	round(val) {
		return Math.round(val);
	},
	numFormat(val, sep = ',') {
		if (!val) return val;
		if (isNaN(parseFloat(val))) return val;

		return methods.numFormat(val, sep);
	},
	tsFormat(val, format = 'YYYY-MM-DD HH:mm:ss') {
		return moment.unix(val).format(format);
	},
	fromNow(val) {
		return moment.unix(val).fromNow();
	},
	json(val) {
		return JSON.stringify(val);
	},
	toFixed(num, len = 2) {
		return Number(num).toFixed(len);
	},
	abs(num) {
		return Math.abs(num);
	},
	UTC(time, format = 'YYYY-MM-DD HH:mm:ss') {
		return moment(time).utc().format(format) + ' UTC';
	},
	md5(val) {
		if (!val) return '';
		return md5(val);
	}
};