var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ifCopy
    ? _c(
        "span",
        [
          _c("span", {
            directives: [
              {
                name: "title",
                rawName: "v-title",
                value: "Copy to clipboard",
                expression: "'Copy to clipboard'"
              }
            ],
            staticClass: "fa fa-clipboard",
            on: { click: _vm.copy }
          }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.copyMsg
              ? _c("div", { staticClass: "copy-msg" }, [
                  _vm._v(_vm._s(_vm.copyMsg))
                ])
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }