'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import mixin from './scripts/mixin';
import config from './scripts/config';
import routesConfig from './scripts/routes'

import './styles/panel.less';
import 'font-awesome/css/font-awesome.css';
import './assets/flags/flags.min.css';

Vue.config.keyCodes = {
	'page-up': 33,
	'page-down': 34
};

Vue.mixin(mixin);
Vue.use(VueRouter);

let requireComponent = require.context('./components', false);
requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName);
	let tag = fileName.slice(2).slice(0, -4).replace(/([A-Z])/g, (m) => '-' + m.toLowerCase()).replace(/^-/, '');
	Vue.component(tag, componentConfig.default || componentConfig);
});

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes: []
});
router.afterEach((to, from) => {
	Vue.nextTick(() => {
		document.dispatchEvent(new Event('render-event'));
	});
});

export default {
	name: 'app',
	router,
	data() {
		return {
			rootDomain: null,

			query: {},
			params: {},
			
			authForm: null,
			
			sidebarMinimized: false,
			footerVisible: false,

			eventsMap: {},

			isLoading: false,

			modalPopup: {
				type: null,
				msg: '',
				promptTitle: '',
				promptType: false
			},
			
			topPages: config.topPages,
			sidebarPages: config.sidebarPages,
			
			currentPage: null,
			isOverlay: false,
			
			firebaseMessaging: null,
			curScrollTop: 0,
			titleStyle: {},
			titleAddClass: '',
			validTitle: '',
			cancelTokens: {
				emptyToken: ''
			},

			geoMap: {},
			user: {},
			idu: null,
			timeDiff: null,

			win: {
				width: null,
				height: null,
				SM: 576,
				MD: 768,
				LG: 992,
				XL: 1200
			},

			tools: [
				{key: 'timestamp', name: 'Timestamp converter'},
				{key: 'urlparser', name: 'URL parser'},
				{key: 'redirect', name: 'Redirect chain debugger'},
				{key: 'md5', name: 'MD5 generator'},
				{key: 'json', name: 'JSON beautifier'},
				{key: 'geo', name: 'GEO'},
				{key: 'color', name: 'Color converter'},
			]
		}
	},
	computed: {
		sidebarVisible() {
			return this.$route.meta.sidebar;
		},
		page() {
			return this.$refs.routerView;
		}
	},
	methods: {
		init(data) {
			this.rootDomain = data.rootDomain;

			let routesKey = data.partner;
			if (data.subDomain) {
				routesKey += '#' + data.subDomain;
			}
			let routes = routesConfig[routesKey];
			if (data.subDomain && routes.filter(r => r.path === '*').length === 0) {
				let notFoundRoute = routesConfig[data.partner].filter(r => r.path === '*')[0];
				if (notFoundRoute) {
					routes.push(notFoundRoute);
				}
			}
			router.addRoutes(routes);

			this.refreshUserStatus();
			this.initPanel();
			this.initDatetimeUpdate();

			this.initSidebar();
			this.initFastspring();

			this.on('userinfo_changed', () => {
				this.refreshUserStatus();
			});

			window.addEventListener('storage', e => {
				if (e.key === 'idu') {
					this.refreshUserStatus();
				}
			});
		},
		async initPanel() {
			let serverTime;
			[this.user, serverTime] = await this.multiGet(['User.getCur', 'Misc.getServerTime']);
			this.timeDiff = Math.round(Date.now() / 1000) - serverTime;
		},
		initSidebar() {
			if (localStorage.getItem('sidebarMinimized')) {
				this.sidebarMinimized = true;
			}
		},
		onHeaderPay(e) {
			this.loadPaymentPopup(e.amount)
		},
		async refreshUserStatus() {
			this.user = await this.get('User.getCur') || {};
		},
		async logout() {
			let ok = await this.confirm('Are you sure you want to logout?');
			if (!ok) return;
			
			await this.post('User.logout');
			this.refreshUserStatus();
		},
		getAppClass() {
			let classes = [];
			if (!this.sidebarVisible) {
				classes.push('sidebar-hidden');
			} else if (this.sidebarMinimized) {
				classes.push('sidebar-minimized');
			}
			if (this.isLoading) {
				classes.push('page-loading');
			}
			return classes.join(' ');
		},
		getSidebarStatus() {
			if (!this.sidebarVisible) return 'HIDDEN';
			if (this.sidebarMinimized) return 'MINIMIZED';
			return 'NORMAL';
		},
		launchModalPopup(r) {
			this.onModalAction(r);
		},
		onAuthModeChanged(e) {
			let header;
			if (e.mode === 'register') {
				header = 'Register';
			} else if (e.mode === 'forgot') {
				header = 'Forgot password';
			} else {
				header = 'Login';
			}
			this.$set(this.authForm, 'header', header);
		},
		toggleSidebar() {
			this.sidebarMinimized = !this.sidebarMinimized;
			if (this.sidebarMinimized) {
				localStorage.setItem('sidebarMinimized', 1);
			} else {
				localStorage.removeItem('sidebarMinimized');
			}
			
			if (window.Highcharts && window.Highcharts.charts) {
				for (let i = 1; i <= 2; i++) {
					setTimeout(() => {
						window.Highcharts.charts.filter(hc => hc).forEach(hc => hc.reflow());
					}, 500 * i);
				}
			}
		},
		initDatetimeUpdate() {
			setInterval(() => {
				//this.$store.commit('updateDatetime');
			}, 10000);
		},
		hideAllTitles() {
			this.titleStyle = {};
		},
		initWebPush() {
			let notifSupported = 'Notification' in window;
			let swSupported = 'serviceWorker' in navigator;
			
			if (!notifSupported) return;
			if (!swSupported) return;
			
			if (!this.worker || !this.worker.id) return;
			
			if (!this.firebaseMessaging) {
				firebase.initializeApp({
					messagingSenderId: '763019028796'
				});
				
				this.firebaseMessaging = firebase.messaging();
				
				if (Notification.permission === 'granted') {
					this.saveWebPushToken();
				} else {
					this.isDevicePushShown = true;
				}
			}
		},
		subscribeWebPush() {
			this.isOverlay = true;
			this.firebaseMessaging.requestPermission().then(() => {
				this.isOverlay = false;
				
				this.saveWebPushToken({clicked: true});
			}).catch(err => {
				this.isOverlay = false;
				
				console.warn('Cannot get notification permission', err);
				alert(err.code + '\n' + err.message);
			});
		},
		saveWebPushToken(opts = {}) {
			this.firebaseMessaging.getToken().then(clientToken => {
				if (!clientToken) {
					console.warn('Cannot get token');
					return;
				}
				
				axios.post('/api/webpush/save-token', {
					idw: this.worker.id,
					token: clientToken,
					clicked: opts.clicked
				}).then(res => {
					this.isDevicePushShown = !res.data.tokenId || res.data.idw !== this.worker.id;
				});
			}).catch(err => {
				console.warn('Token error', err);
				alert(err);
			});
		},
		scrollToTop() {
			window.scrollTo(0, 20);
			
			let scrollDuration = 100;
			let stepInterval = 15;
			let scrollStep = -this.scrollTop() / (scrollDuration / stepInterval);
			let scrollInterval = setInterval(() => {
				if (this.scrollTop() !== 0) {
					window.scrollBy(0, scrollStep);
				} else {
					clearInterval(scrollInterval);
				}
			}, stepInterval);
		},
		onPanelScroll(e) {
			this.curScrollTop = this.scrollTop();

			if (!this.page.onScrollToBottom) return;

			let OFFSET = 200;

			let scrollBottom = this.curScrollTop + window.innerHeight;
			let docHeight = document.body.scrollHeight;

			if (scrollBottom > docHeight - OFFSET) {
				this.page.onScrollToBottom();
			}
		},
		listenSocket() {
			if (this.idu) {
				let room = 'idu' + this.idu;
				socket && socket.emit('join', {room});
			}
		},
		initWin() {
			this.updateWinSize(this.getWinWidth(), this.getWinHeight());
			window.addEventListener('resize', e => {
				this.updateWinSize(this.getWinWidth(), this.getWinHeight());
			});
		},
		updateWinSize(width, height) {
			this.win.width = width;
			this.win.height = height;
		},
		initFastspring() {
			let script = document.createElement('script');
			script.setAttribute('id', 'fsc-api');
			script.setAttribute('src', 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.7.5/fastspring-builder.min.js');
			script.setAttribute('type', 'text/javascript');
			script.setAttribute('data-storefront', 'seoshoptop.test.onfastspring.com/popup-seoshoptop');
			script.setAttribute('data-access-key', 'HYEDJO07RBYSSHUMG4ILCQ');
			document.head.appendChild(script);
		}
	},
	watch: {
		user() {
			this.idu = this.user.id;
			localStorage.setItem('idu', this.idu);
		},
		$route() {
			this.setLoading(false);
			this.query = {...this.$route.query};
			this.params = {...this.$route.params};

			document.title = this.$route.meta.title;

			let page = location.pathname.split('/')[1];
			document.body.setAttribute('data-p', page);
		}
	},
	mounted() {

	},
	created() {
		window.addEventListener('scroll', this.onPanelScroll);
		this.initWin();
	}
};
