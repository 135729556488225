<template>
	<div class="the-tools">
		<a :href="getToolUrl(tool.key)" v-for="tool in app.tools">{{ tool.name }}</a>
	</div>
</template>

<style lang="less">
	.the-tools {
		padding: 200px 0 0;
		text-align: center;

		a {
			margin: 0 10px;
		}
	}
</style>

<script>
	export default {

	}
</script>