var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "mousedown-outside",
          rawName: "v-mousedown-outside",
          value: _vm.hideSuggestions,
          expression: "hideSuggestions"
        }
      ],
      staticClass: "richsel",
      attrs: { "data-val": _vm.value }
    },
    [
      _vm.app.win.width < _vm.app.win.MD && !_vm.itemsUrl
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectVal,
                  expression: "selectVal"
                }
              ],
              style: { width: _vm.width ? _vm.width + "px" : "auto" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectVal = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.select
                ]
              }
            },
            [
              !_vm.noEmpty
                ? _c("option", { domProps: { value: void 0 } })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.resItems, function(item) {
                return item
                  ? _c("option", { domProps: { value: item.key } }, [
                      _vm._v(_vm._s(item.val))
                    ])
                  : _vm._e()
              })
            ],
            2
          )
        : _c("div", [
            _c("i", { staticClass: "richsel-arrow fa fa-caret-down" }),
            _vm._v(" "),
            _vm.valHtml
              ? _c("div", {
                  staticClass: "richsel-val-html",
                  domProps: { innerHTML: _vm._s(_vm.valHtml) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              staticClass: "richsel-val-tf",
              style: { width: _vm.width ? _vm.width + "px" : "auto" },
              attrs: {
                type: "text",
                placeholder: _vm.placeholder,
                readonly: ""
              },
              domProps: { value: !_vm.valHtml ? _vm.tfText : "" },
              on: { focus: _vm.showSuggestions }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.suggestionsShown,
                    expression: "suggestionsShown"
                  }
                ],
                ref: "suggestBox",
                class: [
                  "richsel-dd",
                  "richsel-dd-" + _vm.ddPos,
                  "richsel-dd-" + _vm.ddPosY
                ],
                style: { width: _vm.ddWidth ? _vm.ddWidth + "px" : "auto" }
              },
              [
                _c("div", { staticClass: "richsel-search-box" }, [
                  !_vm.noSearch
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVal,
                            expression: "searchVal"
                          }
                        ],
                        ref: "searchTf",
                        staticClass: "richsel-search-tf",
                        attrs: {
                          type: "text",
                          placeholder: _vm.searchPlaceholder || "Search"
                        },
                        domProps: { value: _vm.searchVal },
                        on: {
                          keydown: [
                            function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k($event.keyCode, "down", 40, $event.key, [
                                  "Down",
                                  "ArrowDown"
                                ])
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.moveDown($event)
                            },
                            function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k($event.keyCode, "up", 38, $event.key, [
                                  "Up",
                                  "ArrowUp"
                                ])
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.moveUp($event)
                            },
                            function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "page-up",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.moveUpFast($event)
                            },
                            function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "page-down",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.moveDownFast($event)
                            },
                            function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.choose($event)
                            },
                            function($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "esc",
                                  27,
                                  $event.key,
                                  "Escape"
                                )
                              ) {
                                return null
                              }
                              return _vm.hideSuggestions($event)
                            }
                          ],
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchVal = $event.target.value
                            },
                            function($event) {
                              !_vm.items && _vm.itemsUrl
                                ? _vm.loadItems()
                                : null
                            }
                          ]
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      { name: "block-scroll", rawName: "v-block-scroll" }
                    ],
                    ref: "itemsBox",
                    staticClass: "richsel-dd-items"
                  },
                  _vm._l(_vm.filteredItems, function(item, index) {
                    return item
                      ? _c(
                          "div",
                          {
                            ref: "item" + item.key,
                            refInFor: true,
                            class: [
                              "richsel-dd-item",
                              { "richsel-hilited": index === _vm.hiliteIndex }
                            ],
                            attrs: { title: item.title },
                            on: {
                              mouseover: function($event) {
                                _vm.hiliteIndex = index
                              },
                              click: _vm.choose
                            }
                          },
                          [
                            item.html
                              ? _c("div", {
                                  domProps: { innerHTML: _vm._s(item.html) }
                                })
                              : _c("div", [_vm._v(_vm._s(item.val || " "))])
                          ]
                        )
                      : _c("div", { staticClass: "richsel-dd-sep" })
                  })
                ),
                _vm._v(" "),
                _vm.noData
                  ? _c(
                      "em",
                      { staticClass: "richsel-alter-items no-data-item" },
                      [_vm._v("\n\t\t\t\tNo data\n\t\t\t")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataLoading
                  ? _c(
                      "div",
                      { staticClass: "richsel-alter-items loding-item" },
                      [
                        _c("i", { staticClass: "fa fa-spinner spin-fast" }),
                        _vm._v("\n\t\t\t\tLoading...\n\t\t\t")
                      ]
                    )
                  : _vm._e()
              ]
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }