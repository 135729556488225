<template>
	<div class="chart">
		<div class="chart-info-right" v-if="serverTime && !type && withStat">
			<em>API time: {{ apiTime | toFixed(5) }}</em>
			<em>API request: {{ apiRequest | toFixed(5) }}</em>
			<em>Server time: {{ serverTime | toFixed(5) }}</em>
			<em>Total time: {{ totalTime | toFixed(5) }}</em>
		</div>
		<div v-if="isLoading" class="chart-loading">
			<span>
				<i class="fa fa-spinner spin-fast center"></i>
				<span class="general-upper">Chart loading...</span>
			</span>
		</div>
		<highcharts v-if="series && !isLoading" :options="getChartOpts()"></highcharts>
	</div>
</template>

<style lang="less">
	@import "../styles/mixin";

	.chart {
		position: relative;
		background: #fff;
		overflow: hidden;
		.box-shadow();

		.chart-loading {
			width: 100%;
			height: 400px;
			line-height: 400px;
			text-align: center;
			font-size: 14px;
			background-color: #fff;
		}
		.chart-info {
			min-height: 20px;
		}
		.chart-info-left {
			float: left;
			text-transform: uppercase;
			font-weight: 500;
		}
		.chart-info-right {
			position: absolute;
			padding: 5px 10px 0 0;
			right: 0;
			z-index: 1;

			em {
				margin-left: 20px;
			}
		}
	}
</style>

<script>
	import Vue from 'vue';
	import VueHighcharts from 'vue-highcharts';
	import Highcharts from 'highcharts';
	import axios from 'axios';
	import _ from 'lodash';

	Highcharts.setOptions({
		global: {useUTC: false}
	});

	Vue.use(VueHighcharts, {Highcharts});

	export default {
		props: ['url', 'type', 'side-text', 'title', 'minLabelValue', 'with-stat'],
		data() {
			return {
				totalTime: null,
				serverTime: null,
				apiTime: null,
				apiRequest: null,
				isLoading: true,
				series: null,
				dates: null,
				tops: null,
				extOpts: null
			};
		},
		methods: {
			getChartOpts() {
				let opts;
				if (!this.type) {
					opts = {
						title: {
							text: this.title,
							style: {
								textTransform: 'uppercase',
								fontWeight: 500
							}
						},
						chart: {
							spacingTop: 35,
							marginTop: 85,
							style: {
								fontFamily: this.getChartFonts()
							}
						},
						rangeSelector: {
							enabled: false
						},
						colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
						tooltip: {
							hideDelay: 100,
							shared: true,
							crosshairs: true
						},
						yAxis: {
							title: {
								text: this.sideText
							},
							labels: {},
							min: 0
						},
						legend: {},
						xAxis: {
							categories: this.dates
						},
						plotOptions: {
							line: {
								dataLabels: {
									enabled: false //true for numbers data all over the graphic line
								},
								enableMouseTracking: true
							}
						},
						series: this.series
					};
				} else if (this.type === 'pieColumn') {
					opts = {
						title: {
							text: this.title,
							style: {
								textTransform: 'uppercase',
								fontWeight: 500
							}
						},
						chart: {
							spacingTop: 35,
							marginTop: 85,
							style: {
								fontFamily: this.getChartFonts()
							}
						},
						colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
						tooltip: {
							hideDelay: 100
						},
						yAxis: {
							title: {
								text: this.sideText
							},
							labels: {},
							min: 0
						},
						legend: {
							layout: 'vertical',
							align: 'right',
							verticalAlign: 'middle'
						},
						xAxis: {
							categories: ['']
						},
						plotOptions: {
							series: {
								dataLabels: {
									enabled: true //true for numbers data all over the graphic line
								}
							}
						},
						series: this.series
					};
				}
				if (this.minLabelValue) {
					let min = this.minLabelValue;
					_.merge(opts, {
						yAxis: {
							labels: {
								formatter: function() {
									if (this.value < min) return min;
									return this.value;
								}
							}
						}
					});

				}
				if (this.extOpts) {
					_.merge(opts, this.extOpts);
				}
				return opts;
			},
			getChartFonts() {
				return 'Roboto, "Open Sans", sanf-serif, helvetica, tahoma, verdana';
			},
			async getChartsData() {
				this.isLoading = true;
				this.totalTime = null;
				this.serverTime = null;
				let startTime = Date.now();
				let query = location.search.replace(/^\?/, '');

				let params = {query};
				//await Util.loadScript('/lib/highstock.concat.js', true);
				/*Highcharts.setOptions({
					global: {useUTC: false}
				});*/
				let res = await axios(this.url, {params});

				this.isLoading = false;
				this.totalTime = (Date.now() - startTime) / 1000;
				this.serverTime = parseFloat(res.headers['x-request-time']);
				this.series = res.data.series;
				this.apiTime = res.data.apiTime;
				this.dates = res.data.dates;
				this.apiRequest = res.data.methodTime;
				this.extOpts = res.data.extOpts;
			}
		},
		watch: {
			url() {
				this.getChartsData();
			}
		},
		mounted() {
			this.getChartsData();
		}
	};
</script>