<template>
	<div>
		<h1>This is <strong>new</strong> Semalt</h1>
	</div>
</template>

<style scoped lang="less">
	h1 {
		padding: 20px 0;
		text-transform: uppercase;
		font-weight: 500;
		text-align: center;
	}
</style>

<script>
	export default {
		mounted() {
			document.dispatchEvent(new Event('render-event'));
		}
	};
</script>