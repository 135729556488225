export default {
	focus: {
		inserted(el, binding, vnode) {
			if (binding.value && binding.value.minWidth) {
				if (vnode.context.win.width < binding.value.minWidth) return;
			}
			el.focus();
		}
	},
	title: {
		update(el, binding, vnode) {
			el.customData = el.customData || {};
			el.customData.titleText = binding.value;
		},
		bind(el, binding, vnode) {
			el.customData = el.customData || {};
			el.customData.titleText = binding.value;
			el.addEventListener('mouseenter', e => {
				if (!el.customData.titleText) return;

				let $root = vnode.context.app;
				let title = $root.$refs.title;
				title.textContent = el.customData.titleText;

				let elRect = vnode.elm.getBoundingClientRect();
				let left, top;

				if (binding.modifiers.bottom) {
					left = elRect.left + (vnode.elm.offsetWidth - title.offsetWidth) / 2;
					top = elRect.top + vnode.elm.offsetHeight + vnode.context.scrollTop();
					$root.titleAddClass = 'pos-bottom';
				} else if (binding.modifiers.left) {
					left = elRect.left - title.offsetWidth - 5;
					top = elRect.top + vnode.context.scrollTop() + (vnode.elm.offsetHeight - title.offsetHeight) / 2;
					$root.titleAddClass = 'pos-left';
				} else if (binding.modifiers.right) {
					left = elRect.right + 5;
					top = elRect.top + vnode.context.scrollTop() + (vnode.elm.offsetHeight - title.offsetHeight) / 2;
					$root.titleAddClass = 'pos-right';
				} else {
					left = elRect.left + (vnode.elm.offsetWidth - title.offsetWidth) / 2;
					top = elRect.top + vnode.context.scrollTop() - title.offsetHeight;
					$root.titleAddClass = '';
				}

				$root.titleStyle = {
					visibility: 'visible',
					left: left + 'px',
					top: top + 'px'
				};

				el.addEventListener('mouseleave', function leave() {
					$root.titleStyle = {};
					el.removeEventListener('mouseleave', leave);
				});
			});
		},
		unbind(el, binding, vnode) {
			let $root = vnode.context.app;
			let title = $root.$refs.title;
			title.textContent = '';
			$root.titleStyle = {};
		}
	},
	valid: {
		bind(el, binding, vnode) {

			el.addEventListener('change', () => {
				let regexp, error, b = binding.value;
				let message = b && b.message ? b.message : '';
				let className = b && b.className ? b.className : 'v-valid-title';
				let style = b && b.style ? b.style : '';
				let is = vnode.context.app;
				let title = is.$refs.validTitle;

				if (!el.value) {
					title.style.visibility = 'hidden';
					is.validTitle = '';
					return;
				}

				let x = el.getBoundingClientRect().left;
				let y = el.getBoundingClientRect().bottom;

				switch(binding.arg){
					case 'email':
						regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						message = message || 'Incorrect email';
						error = !regexp.test(el.value);
						break;
					case 'tel':
						regexp = /\D+/g;
						message = message || 'Incorrect phone number';
						let trueNum = '' + el.value.replace(regexp, '');
						el.value = '+' + trueNum;
						error = trueNum.length !== 12;
						break;
					case 'pass':
						if (el.value.length < 8) {
							message = 'The password must contain at least eight characters';
							error = true;
							break;
						}
						regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
						message = "Probably pass doesn't contain large letter, small letter or digit";
						error = !regexp.test(el.value);
						break;
				}

				if (error) {
					is.validTitle = message;
					style = `${style};visibility: visible; top: ${y}px; left: ${x}px`;
					title.style = style;
					title.classList.add(className);
				}
				else {
					title.style.visibility = 'hidden';
					is.validTitle = '';
				}
			});
		}
	},
	'horiz-scroll': {
		bind(el) {
			let onWheel = e => {
				if (e.currentTarget.offsetWidth === e.currentTarget.scrollWidth) return;

				e.preventDefault();
				if (!e.deltaY) return;

				let dist = e.deltaY;

				if (Math.abs(dist) < 20) {
					dist = 20 * (dist > 0 ? 1 : -1);
				}

				e.currentTarget.scrollLeft += dist;
			};

			el.__vueOnWheel__ = onWheel;
			el.addEventListener('wheel', onWheel);
		},
		unbind(el) {
			if (!el.__vueOnWheel__) return;

			el.removeEventListener('wheel', el.__vueOnWheel__);
		}
	},
	'block-scroll': {
		bind(el, binding, vNode) {
			let handler = e => {
				e.stopPropagation();

				if (el.scrollHeight <= el.offsetHeight) return;

				if (
					e.deltaY < 0 && el.scrollTop <= 0 ||
					e.deltaY > 0 && el.scrollTop + el.offsetHeight >= el.scrollHeight
				) {
					e.preventDefault();
				}
			};

			el.__vueMousewheelOutside__ = handler;

			el.addEventListener('wheel', handler);
		},
		unbind(el) {
			el.addEventListener('wheel', el.__vueMousewheelOutside__);
		}
	},
	'mousedown-outside': {
		bind(el, binding, vNode) {
			if (typeof binding.value !== 'function') {
				const compName = vNode.context.name;
				let warn = `[Vue-mousedown-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
				if (compName) {
					warn += `Found in component '${compName}'`;
				}

				console.warn(warn);
			}

			const bubble = binding.modifiers.bubble;
			const handler = (e) => {
				if (bubble || (!el.contains(e.target) && el !== e.target)) {
					binding.value(e);
				}
			};
			el.__vueMousedownOutside__ = handler;

			document.addEventListener('mousedown', handler);
		},

		unbind(el, binding) {
			document.removeEventListener('mousedown', el.__vueMousedownOutside__);
			el.__vueMousedownOutside__ = null;
		}
	}
};