<template>
	<div class="richsel" :data-val="value" v-mousedown-outside="hideSuggestions">
		<select
			v-if="app.win.width < app.win.MD && !itemsUrl"
			v-model="selectVal"
			:style="{width: width ? width + 'px' : 'auto'}"
			@change="select"
		>
			<option v-if="!noEmpty" :value="void 0"></option>
			<option v-for="item in resItems" v-if="item" :value="item.key">{{ item.val }}</option>
		</select>
		<div v-else>
			<i class="richsel-arrow fa fa-caret-down"></i>
			<div v-if="valHtml" class="richsel-val-html" v-html="valHtml"></div>
			<input
				type="text"
				class="richsel-val-tf"
				:style="{width: width ? width + 'px' : 'auto'}"
				:value="!valHtml ? tfText : ''"
				:placeholder="placeholder"
				@focus="showSuggestions"
				readonly
			>
			<div
				ref="suggestBox"
				:class="['richsel-dd', 'richsel-dd-' + ddPos, 'richsel-dd-' + ddPosY]"
				:style="{width: ddWidth ? ddWidth + 'px' : 'auto'}"
				v-show="suggestionsShown"
			>
				<div class="richsel-search-box">
					<input
						v-if="!noSearch"
						type="text"
						class="richsel-search-tf"
						v-model="searchVal"
						ref="searchTf"
						@keydown.down.prevent="moveDown"
						@keydown.up.prevent="moveUp"
						@keydown.page-up.prevent="moveUpFast"
						@keydown.page-down.prevent="moveDownFast"
						@keydown.enter.prevent="choose"
						@keydown.esc="hideSuggestions"
						@input="(!items && itemsUrl) ? loadItems() : null"
						:placeholder="searchPlaceholder || 'Search'"
					>
				</div>
				<div class="richsel-dd-items" ref="itemsBox" v-block-scroll>
					<div
						v-for="(item, index) in filteredItems" v-if="item"
						:class="['richsel-dd-item', {'richsel-hilited': (index === hiliteIndex)}]"
						:ref="'item' + item.key"
						:title="item.title"
						@mouseover="hiliteIndex = index"
						@click="choose"
					>
						<div v-if="item.html" v-html="item.html"></div>
						<div v-else>{{ item.val || '&nbsp;' }}</div>
					</div>
					<div v-else class="richsel-dd-sep"></div>
				</div>
				<em v-if="noData" class="richsel-alter-items no-data-item">
					No data
				</em>
				<div v-if="dataLoading" class="richsel-alter-items loding-item">
					<i class="fa fa-spinner spin-fast"></i>
					Loading...
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				suggestionsShown: false,
				searchVal: '',
				hiliteIndex: 0,
				movingFastStep: 10,
				ddPos: null,
				ddPosY: null,
				selectVal: null,
				valHtml: null,
				resItems: [],
				time: new Date().getTime(),
				source: null,
				timer: null,
				dataLoading: false,
				noData: false,
				defaultDelay: 300
			};
		},
		props: [
			'value', 'items', 'items-url', 'no-sort', 'key-ignore-case', 'no-empty',
			'placeholder', 'search-placeholder', 'default-text', 'delay', 'no-search',
			'width', 'dd-width'
		],
		computed: {
			tfText() {
				let item = this.getItemByValue();
				if (!item) return this.defaultText || this.value || '';
				return item.displayVal || item.val;
			},
			shouldSort() {
				let elemsWithSort = this.resItems.filter(it => it && it.sortVal != null);
				return !!elemsWithSort.length;
			},
			sortedItems() {
				if (!this.resItems || this.noSort || !this.shouldSort) return this.resItems;

				let items = this.resItems.sort((a, b) => {
					if (!a || !b) return 0;

					if (a.sortVal == null && b.sortVal == null) return 0;

					if (a.sortVal > b.sortVal) return 1;
					if (a.sortVal < b.sortVal) return -1;

					return 0;
				});
				return items;
			},
			filteredItems() {
				if (!this.sortedItems || !this.sortedItems.length) return this.sortedItems;
				let items = this.sortedItems.filter(this.checkItemMatch);
				if (this.searchVal || this.noEmpty) return items;

				let emptyItem = {key: null, val: ''};
				return [emptyItem, ...items];
			},
			trueDelay() {
				return parseInt(this.delay) || this.defaultDelay;
			}
		},
		methods: {
			showSuggestions() {
				if (!this.items && this.itemsUrl) {
					this.resItems = [];
				}
				this.suggestionsShown = true;
				this.ddPos = 'left';
				this.ddPosY = 'bottom';

				let item = this.getItemByValue();
				let index = this.filteredItems.indexOf(item);
				if (index === -1) {
					index = 0;
				}
				this.hiliteIndex = index;

				this.$nextTick(() => {
					this.tryScroll();
					this.$refs.searchTf && this.$refs.searchTf.focus();

					let rect = this.$refs.suggestBox.getBoundingClientRect();

					let overlayX = rect.right - window.innerWidth + 50;
					this.ddPos = overlayX < 0 ? 'left' : 'right';

					let overlayY = rect.bottom - window.innerHeight;
					//this.ddPosY = overlayY < 0 ? 'bottom' : 'top';
				});
			},
			hideSuggestions() {
				this.suggestionsShown = false;
				this.searchVal = '';
			},
			getItemByValue() {
				if (!this.resItems) return;

				let key = this.value;
				if (this.keyIgnoreCase && key) {
					key = key.toLowerCase();
					return this.resItems.filter(item => item && String(item.key).toLowerCase() === key)[0];
				}
				return this.resItems.filter(item => item && item.key == key)[0];
			},
			getHilitedItem() {
				return this.filteredItems[this.hiliteIndex];
			},
			checkItemMatch(item) {
				if (!this.searchVal) return true;

				if (!item) return false;

				let regex = new RegExp(this.escapeRegex(this.searchVal), 'i');
				return regex.test(item.sVal || item.val)
			},
			moveUp(opt) {
				if (--this.hiliteIndex < 0) {
					this.hiliteIndex = this.filteredItems.length - 1;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			moveDown(opt) {
				if (++this.hiliteIndex >= this.filteredItems.length) {
					this.hiliteIndex = 0;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			moveUpFast(opt) {
				this.hiliteIndex -= this.movingFastStep;
				if (this.hiliteIndex < 0) {
					this.hiliteIndex = 0;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveUp({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			moveDownFast(opt) {
				this.hiliteIndex += this.movingFastStep;
				if (this.hiliteIndex >= this.filteredItems.length) {
					this.hiliteIndex = this.filteredItems.length - 1;
				}
				if (!opt.repeated && !this.getHilitedItem()) return this.moveDown({repeated: true});
				this.$nextTick(this.tryScroll);
			},
			choose() {
				let item = this.filteredItems[this.hiliteIndex];
				if (!item) return;

				this.hideSuggestions();

				this.selectVal = item.key;
				this.valHtml = item.valHtml;

				this.$emit('input', item.key);
				this.$emit('change', {isTrusted: true});
			},
			select() {
				this.$emit('input', this.selectVal);
				this.$emit('change', {isTrusted: true});
			},
			tryScroll() {
				let item = this.filteredItems[this.hiliteIndex];
				if (!item) return;

				let itemNodes = this.$refs['item' + item.key];
				if (!itemNodes || !itemNodes[0]) return;

				let itemNode = itemNodes[0];
				let itemPos = itemNode.getBoundingClientRect();

				let itemsBox = this.$refs.itemsBox;
				let itemsBoxPos = itemsBox.getBoundingClientRect();

				let extraDistBottom = itemPos.bottom - itemsBoxPos.bottom;
				if (extraDistBottom > 0) {
					itemsBox.scrollTop += extraDistBottom;
				}

				let extraDistTop = itemsBoxPos.top - itemPos.top;
				if (extraDistTop > 0) {
					itemsBox.scrollTop -= extraDistTop;
				}
			},
			loadItems() {
				this.dataLoading = false;
				this.noData = false;
				this.resItems = [];
				if (this.timer) clearTimeout(this.timer);
				if (!this.searchVal) {
					this.source.cancel('');
					return;
				}
				if (this.timer) clearTimeout(this.timer);

				this.timer = setTimeout(() => {
					if (!this.source) {
						this.source = axios.CancelToken.source();
					}
					else {
						this.source.cancel('');
					}

					this.source = axios.CancelToken.source();
					this.noData = false;
					this.dataLoading = true;

					let req = '';
					if (this.itemsUrl.indexOf('?') >= 0) req = '&q=';
					else req = '?q=';

					axios.get(this.itemsUrl + req + this.searchVal, {
						cancelToken: this.source.token
					})
						.then(data => {
							if (!data) return;
							if (!data.data.items) this.noData = true;
							else this.resItems = data.data.items.slice(0, 10);
							this.dataLoading = false;
						})
						.catch(err => {
							console.log(err);
						});
				}, this.trueDelay);
			},
			updateResItems() {
				this.resItems = this.items ? [...this.items] : [];
			},
			updateValHtml() {
				this.resItems.filter(item => item.key === this.value).forEach(item => {
					this.valHtml = item.valHtml;
				});
			}
		},
		watch: {
			searchVal() {
				this.hiliteIndex = 0;
			},
			value() {
				this.selectVal = this.value;
			},
			items() {
				this.updateResItems();
			},
			selectVal() {
				this.updateValHtml();
			}
		},
		created() {
			this.selectVal = this.value;
			this.updateResItems();
			this.updateValHtml();
		}
	}
</script>

<style lang="less">
	@import "../styles/mixin";

	.richsel {
		display: inline-block;
		position: relative;
		background: #fff;

		* {
			text-align: left;

			&:not(.fa):not(.zmdi) {
				.open-sans;
			}
		}

		select {
			padding-top: 0 !important;
			width: 100%;
		}

		.richsel-arrow {
			position: absolute;
			top: 6px;
			right: 6px;
			font-size: 10px;
			background: transparent;
		}

		.richsel-val-html {
			position: absolute;
			padding: 0 0 0 5px;
			line-height: 23px;
		}

		.richsel-val-tf {
			position: relative;
			padding-right: 15px !important;
			width: 100%;
			background: transparent !important;
		}

		.richsel-search-box {
			padding: 4px;
		}

		.richsel-dd {
			position: absolute;
			margin-top: -1px;
			left: 0;
			min-width: 100%;
			background: #fff;
			border: 1px solid #9a9a9a;
			z-index: 1000;

			&.richsel-dd-right {
				right: 0;
			}

			&.richsel-dd-top {
				bottom: 22px;
			}

			.richsel-search-tf {
				width: 100% !important;
			}

			.richsel-dd-items {
				max-height: 300px;
				overflow-y: scroll;
				.scrollbar(3px);

				.richsel-dd-item {
					padding: 0 5px 0 4px;
					cursor: pointer;
					white-space: nowrap;

					&.richsel-hilited {
						color: #fff;
						background: @primColor;
					}
				}

				.richsel-dd-sep {
					height: 6px;
				}
			}

			.richsel-alter-items {
				display: block;
				text-align: center;
				margin: 5px;
			}
		}
	}
</style>