<template>
	<span v-if="ifCopy">
		<span
			class="fa fa-clipboard"
			v-title="'Copy to clipboard'"
			@click="copy"
		></span>
		<transition name="fade">
			<div v-if="copyMsg" class="copy-msg">{{copyMsg}}</div>
		</transition>
	</span>
</template>

<script>
	export default {
		props: ['text'],
		data() {
			return {
				copyMsg: null
			};
		},
		computed: {
			ifCopy () {
				return 	navigator.clipboard.writeText
			},
		},
		methods: {
			async copy() {
				let res = await navigator.clipboard.writeText(this.text);
				this.copyMsg = "Copied to clipboard";
				setTimeout(() => {
					this.copyMsg = null;
				}, 2000);
			}
		}
	}
</script>

<style lang="less">
	.fa {
		cursor: pointer;
	}
	.copy-msg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 220px;
		height: 100px;
		margin: auto;
		background: rgba(60, 99, 117, .8);
		color: white;
		text-align: center;
		font-size: 20px;
		padding: 13px;
		line-height: 70px;
		border-radius: 3px;
	}
</style>