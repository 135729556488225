var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "mousedown-outside",
          rawName: "v-mousedown-outside",
          value: _vm.hideSuggestions,
          expression: "hideSuggestions"
        }
      ],
      staticClass: "phone-input",
      attrs: { "data-val": _vm.value }
    },
    [
      _c("div", [
        _c(
          "div",
          {
            ref: "codeHtml",
            staticClass: "phone-val-html",
            on: { click: _vm.showSuggestions }
          },
          [
            _c("base-flag", { attrs: { cc: _vm.phoneGeo } }),
            _vm._v(" "),
            _c("span", [_vm._v("+")])
          ],
          1
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.number,
              expression: "number"
            }
          ],
          ref: "numberTf",
          staticClass: "phone-val-tf",
          attrs: { type: "text", placeholder: _vm.placeholder },
          domProps: { value: _vm.number },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.number = $event.target.value
              },
              _vm.onNumberInput
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.suggestionsShown,
                expression: "suggestionsShown"
              }
            ],
            ref: "suggestBox",
            class: [
              "phone-dd",
              "phone-dd-" + _vm.ddPos,
              "phone-dd-" + _vm.ddPosY
            ]
          },
          [
            _c("div", { staticClass: "phone-search-box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchVal,
                    expression: "searchVal"
                  }
                ],
                ref: "searchTf",
                staticClass: "phone-search-tf",
                attrs: {
                  type: "text",
                  placeholder: _vm.searchPlaceholder || "Search"
                },
                domProps: { value: _vm.searchVal },
                on: {
                  keydown: [
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.moveDown($event)
                    },
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.moveUp($event)
                    },
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k(
                          $event.keyCode,
                          "page-up",
                          undefined,
                          $event.key,
                          undefined
                        )
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.moveUpFast($event)
                    },
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k(
                          $event.keyCode,
                          "page-down",
                          undefined,
                          $event.key,
                          undefined
                        )
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.moveDownFast($event)
                    },
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.choose($event)
                    },
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
                      ) {
                        return null
                      }
                      return _vm.hideSuggestions($event)
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchVal = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  { name: "block-scroll", rawName: "v-block-scroll" }
                ],
                ref: "itemsBox",
                staticClass: "phone-dd-items"
              },
              _vm._l(_vm.filteredItems, function(item, index) {
                return item
                  ? _c(
                      "div",
                      {
                        ref: "item" + item.key,
                        refInFor: true,
                        class: [
                          "phone-dd-item",
                          { "phone-hilited": index === _vm.hiliteIndex }
                        ],
                        attrs: { title: item.title },
                        on: {
                          mouseover: function($event) {
                            _vm.hiliteIndex = index
                          },
                          click: _vm.choose
                        }
                      },
                      [
                        item.html
                          ? _c("div", {
                              domProps: { innerHTML: _vm._s(item.html) }
                            })
                          : _c("div", [_vm._v(_vm._s(item.val || " "))])
                      ]
                    )
                  : _c("div", { staticClass: "phone-dd-sep" })
              })
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }