<template>
	<div class="multisel" v-mousedown-outside="hideItems">
		<span v-show="value" class="multisel-clear zmdi zmdi-close" @click="clear"></span>
		<input type="text" class="multisel-val-tf" :value="tfText" @focus="showItems" readonly>
		<div v-show="itemsShown" ref="itemsBox" :class="'multisel-dd multisel-dd-' + ddPos">
			<div class="multisel-search-box">
				<input
					type="text"
					class="multisel-search-tf"
					v-model="searchVal"
					ref="searchTf"
					@keydown.esc="hideItems"
					@keydown.enter="apply"
					placeholder="Search"
				>
			</div>
			<div>
				<div class="multisel-dd-inner" ref="scrollableBox" v-block-scroll>
					<div class="multisel-presets" v-if="presets && !searchVal">
						<div v-for="preset in presets">
							<a href="javascript:;" @click="choosePreset(preset)">{{ preset.text }}</a>
						</div>
					</div>
					<div v-if="withNot" class="multisel-invert-box">
						<label class="nowrap chb">
							<input
								type="checkbox"
								:checked="inverted"
								@click="setInverted(!inverted)"
							>
							<span>Not</span>
						</label>
					</div>
					<div
						v-for="(item, index) in filteredItems"
						:class="['multisel-dd-item', item.className]"
						:ref="'item' + item.key"
					>
						<label class="nowrap chb">
							<input
								type="checkbox"
								:checked="!!checkedKeysMap[item.key]"
								@click="check($event, item)"
							>
							<span v-if="item.html" v-html="item.html"></span>
							<span v-else>{{ item.val || '&nbsp;' }}</span>
						</label>
					</div>
				</div>
				<div class="multisel-bottom clearfix">
					<span class="left pic-link" @click="checkAll">
						<i class="fa fa-check"></i>
						<span>Check all</span>
					</span>
					<span class="right pic-link" @click="apply">
						<i class="fa fa-save"></i>
						<span>Apply</span>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				itemsShown: false,
				inverted: false,
				movingFastStep: 10,
				checkedKeysMap: {},
				stickyCheckedKeysMap: {},
				ddPos: null,
				searchVal: '',
				tfText: ''
			};
		},
		props: ['value', 'items', 'with-not', 'presets', 'no-sort', 'debug'],
		computed: {
			itemsWithSort() {
				let items = [...this.items].map((item, i) => {
					if (item.sortVal == null) {
						item.sortVal = i;
					}
					return item;
				});
				return items;
			},
			filteredItems() {
				if (!this.items) return this.items;
				let items = this.itemsWithSort.filter(this.checkItemMatch);

				items = items.sort((a, b) => {
					let aChecked = this.stickyCheckedKeysMap[a.key];
					let bChecked = this.stickyCheckedKeysMap[b.key];

					if (!aChecked && bChecked) return 1;
					if (aChecked && !bChecked) return -1;

					if (a.sortVal > b.sortVal) return 1;
					if (a.sortVal < b.sortVal) return -1;

					return 0;
				});

				return items;
			},
			altSearchVal() {
				if (!this.searchVal) return this.searchVal;

				return this.toKeyboardLatin(this.searchVal.toLowerCase());
			}
		},
		methods: {
			showItems() {
				let winWidth = window.innerWidth;

				this.itemsShown = true;
				this.searchVal = '';
				this.ddPos = 'left';

				this.stickyCheckedKeysMap = {...this.checkedKeysMap};

				this.$nextTick(() => {
					let boxRight = this.$refs.itemsBox.getBoundingClientRect().right;
					let overlay = boxRight - winWidth + 10;
					this.ddPos = overlay < 0 ? 'left' : 'right';

					this.tryFocusSearch();
					this.$refs.scrollableBox.scrollTop = 0;
				});
			},
			hideItems() {
				this.itemsShown = false;
				this.updateVal();
			},
			tryFocusSearch() {
				if (this.$refs.searchTf && this.app.win.width >= this.app.win.LG) {
					this.$refs.searchTf.focus();
				}
			},
			updateTfText() {
				let texts = Object.keys(this.checkedKeysMap).map(key => {
					let item = this.checkedKeysMap[key];
					return item.displayVal || item.val;
				});
				this.tfText = (this.inverted ? 'Not: ' : '') + texts.join(', ');
			},
			checkItemMatch(item) {
				if (!this.searchVal) return true;

				if (!item) return false;

				let regex = new RegExp(this.escapeRegex(this.searchVal), 'i');
				if (regex.test(item.sVal || item.val)) return true;

				if (this.altSearchVal === this.searchVal) return false;

				let altRegex = new RegExp(this.escapeRegex(this.altSearchVal), 'i');
				return altRegex.test(item.sVal || item.val);
			},
			setInverted(isInv) {
				this.inverted = isInv;
				this.tryFocusSearch();
			},
			check(e, item) {
				if (e.target.checked) {
					this.$set(this.checkedKeysMap, item.key, item);
				} else {
					this.$delete(this.checkedKeysMap, item.key);
				}
				this.tryFocusSearch();
			},
			updateModel() {
				this.updateTfText();

				let keys = Object.keys(this.checkedKeysMap);
				let val;
				if (keys.length) {
					val = (this.inverted ? 'n_' : '') + keys.join(',');
				} else {
					val = '';
				}

				this.$emit('input', val);
				this.$emit('change', {isTrusted: true});
			},
			updateVal() {
				if (!this.value || !this.items) {
					this.inverted = false;
					this.checkedKeysMap = {};
					return;
				}
				let map = {};
				let val = this.value;
				if (val.indexOf('n_') === 0) {
					val = val.slice(2);
					this.inverted = true;
				} else {
					this.inverted = false;
				}
				val.split(',').forEach(key => {
					let item = this.items.filter(el => el.key == key)[0];
					if (!item) return;

					map[key] = item;
				});
				this.checkedKeysMap = map;
			},
			choosePreset(preset) {
				this.$emit('input', preset.key);
				this.$emit('change', {isTrusted: true});

				this.hideItems();
			},
			clear() {
				this.$emit('input', '');
				this.$emit('change', {isTrusted: true});

				this.hideItems();
			},
			checkAll() {
				let uncheckedExist = this.filteredItems.filter(item => !this.checkedKeysMap[item.key]).length;

				this.filteredItems.forEach(item => {
					if (uncheckedExist) {
						this.$set(this.checkedKeysMap, item.key, item);
					} else {
						this.$delete(this.checkedKeysMap, item.key);
					}
				});

				this.tryFocusSearch();
			},
			apply() {
				this.updateModel();
				this.hideItems();
			}
		},
		watch: {
			value() {
				this.updateVal();
			},
			items() {
				this.updateVal();
				this.updateTfText();
			}
		},
		mounted() {
			this.updateVal();
			this.updateTfText();
		},
		created() {

		}
	}
</script>

<style lang="less">
	@import "../styles/mixin.less";

	.multisel {
		display: inline-block;
		position: relative;
		background: #fff;

		* {
			text-align: left;
		}

		*:not(.fa):not(.zmdi) {
			.open-sans;
		}

		.multisel-clear {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
			z-index: 1;
		}

		.multisel-val-tf {
			position: relative;
			padding-right: 15px !important;
			width: 100%;
			background: transparent !important;
			cursor: text;
		}

		.multisel-search-box {
			padding: 4px;

			.multisel-search-tf {
				width: 100%;
			}
		}

		.multisel-dd {
			position: absolute;
			margin-top: -1px;
			padding: 1px 0 2px;
			min-width: 100%;
			background: #fff;
			border: 1px solid #9a9a9a;
			z-index: 11;

			&.multisel-dd-right {
				right: 0;
			}

			.multisel-search-tf {
				width: 100% !important;
			}

			.multisel-dd-inner {
				max-height: 300px;
				overflow-y: scroll;
				.scrollbar(3px);

				.multisel-presets {
					padding: 2px 4px 5px 4px;

					div {
						padding: 1px 0 0;

						a {
							font-weight: 500;
							font-size: 14px;
							.main-font(500);
						}
					}
				}

				.multisel-invert-box {
					padding: 2px 4px 10px 4px;

					span {
						font-weight: bold;
						text-transform: uppercase;
					}
				}

				.multisel-dd-item {
					padding: 1px 5px 1px 4px;
					cursor: pointer;
					white-space: nowrap;

					label {
						display: block !important;
						font-weight: 400;
						cursor: pointer;
					}

					span {
						opacity: 1;
					}

					&:hover {
						background: @primColor;

						span {
							color: #fff;
						}
					}

					input[type="checkbox"] {
						margin: 0 3px 0 0;
					}
				}

				.multisel-dd-sep {
					height: 6px;
				}
			}

			.multisel-bottom {
				padding: 5px 7px 4px 5px;

				span {
					.main-font;
				}
			}
		}
	}
</style>