<template>
	<div class="calendar">
		<div class="dp-calendar">
			<div v-if="withTime" class="dp-time">
				<rich-sel :items="timesSuggestions" v-model="time"></rich-sel>
			</div>
			<div class="dp-controls">
				<div class="dp-angles-left">
					<i class="fa fa-angle-double-left" @click="changeYear(-1)"></i>
					<i class="fa fa-angle-left" @click="changeMonth(-1)"></i>
				</div>
				<div class="text-center general-upper">{{ stringIndexToMonthName(month) }} {{ year }}</div>
				<div class="dp-angles-right">
					<i class="fa fa-angle-right" @click="changeMonth(1)"></i>
					<i class="fa fa-angle-double-right" @click="changeYear(1)"></i>
				</div>
			</div>
			<div>
				<table>
					<thead>
					<tr>
						<th v-for="day in weekDays">{{ day }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="row in grid">
						<td
							v-for="cell in row"
							:class="getCellClasses(cell)"
							@click="onCellClick(cell)"
						>
							<span v-if="cell">{{ cell.day }}</span>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/var";

	.calendar {
		min-width: 210px;
		.dp-calendar {
			background: #fff;

			.dp-time {
				padding: 3px;
				border: 1px solid #ccc;

				.richsel-val-tf {
					width: 80px;
				}
			}

			.dp-controls {
				position: relative;
				padding: 5px 0;
				border: 1px solid #ccc;
				border-bottom: none;
				font-size: 12px;

				.dp-angles-left, .dp-angles-right {
					position: absolute;
					top: 4px;

					i {
						margin: 0 1px;
						font-size: 18px;
						cursor: pointer;
						color: @primColor;
					}
				}
				.dp-angles-left {
					left: 7px;
				}
				.dp-angles-right {
					right: 7px;
				}
			}

			table {
				border-collapse: collapse;
				th, td {
					min-width: 30px;
					height: 30px;
					border: 1px solid #ccc;
					text-align: center;
				}

				th {
					font-weight: 400;
					background: #e9f0f8;
				}

				td.dp-day {
					cursor: pointer;

					&:hover, &.today {
						background: #f2f6f9;
					}

					&.chosen {
						color: #fff;
						cursor: default;
						background: mix(@primColor, #fff, 80);
						border: 1px solid mix(@primColor, #fff, 80);
					}
				}

				td.dp-day-empty {
					background: #f1f1f1;
				}
			}
		}
	}
</style>

<script>
	import moment from 'moment';

	export default {
		props: ['value', 'with-time', 'unclearable', 'empty-val', 'empty-text'],
		data() {
			return {
				nativeVal: null,
				calendarShown: false,
				weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
				months: [
					'January', 'February', 'March',
					'April', 'May', 'June',
					'July', 'August', 'September',
					'October', 'November', 'December'
				],
				year: null,
				month: null,
				time: '00:00',
				curYear: parseInt(moment().format('YYYY')),
				curDate: moment().format('YYYY-MM-DD'),
				curTime: moment().format('HH:mm'),
				minYear: 2000,
				maxYear: 2020
			};
		},
		computed: {
			tfText() {
				if (this.emptyVal && this.emptyText && this.value === this.emptyVal) return this.emptyText;
				return this.value;
			},
			val() {
				return this.value || this.curDate;
			},
			grid() {
				if (!this.year || !this.month) return [];

				let yearMonth = this.year + '-' + this.month;

				let time = moment(yearMonth + '-01');
				let dayOfWeek = time.day() - 1;
				if (dayOfWeek < 0) {
					dayOfWeek = 6;
				}
				let daysInMonth = time.daysInMonth();

				let grid = [];
				let rowsCnt = Math.ceil((daysInMonth + dayOfWeek) / 7);
				for (let i = 0; i < rowsCnt; i++) {
					let row = [];
					for (let j = 0; j < 7; j++) {
						let cell = null;
						if (i > 0 || j >= dayOfWeek) {
							let day = i * 7 + j - dayOfWeek + 1;
							let date = yearMonth + '-' + (day < 10 ? '0' : '') + day;
							if (day <= daysInMonth) {
								cell = {day, date};
							}
						}
						row.push(cell);
					}
					grid.push(row);
				}

				return grid;
			},
			timesSuggestions() {
				let items = [];

				for (let h = 0; h < 24; h++) {
					for (let m = 0; m < 60; m += 10) {
						let time = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
						items.push({
							key: time,
							val: time
						});
					}
				}

				return items;
			}
		},
		methods: {
			showCalendar() {
				this.updateDatetimeData();
				this.calendarShown = true;
			},
			hideCalendar() {
				this.calendarShown = false;
				this.time = '00:00';
			},
			changeMonth(diff) {
				let newMonth = parseInt(this.month) - 1 + diff;
				if (newMonth < 0) {
					newMonth = 11;
					this.changeYear(-1);
				} else if (newMonth >= 12) {
					newMonth = 0;
					this.changeYear(1);
				}
				this.month = this.monthIndexToString(newMonth);
			},
			changeYear(diff) {
				this.year = parseInt(this.year || this.curYear) + diff;
			},
			onCellClick(cell) {
				if (!cell || cell.date === this.date) return;

				let val = cell.date;
				if (this.withTime) {
					val += ' ' + this.time;
				}
				this.setVal(val);
				// this.hideCalendar();
			},
			updateDatetimeData() {
				if (this.val === this.emptyVal) return;

				this.year = this.val.slice(0, 4);
				this.month = this.val.slice(5, 7);
				if (this.withTime) {
					this.time = this.val.slice(11, 16) || '00:00';
				}
			},
			monthIndexToString(index) {
				let month = String(index + 1);
				if (month.length < 2) {
					month = '0' + month;
				}
				return month;
			},
			stringIndexToMonthName(index) {
				let idx = parseInt(index) - 1;
				return this.months[idx];
			},
			getCellClasses(cell) {
				let classes = [];
				if (cell) {
					classes.push('dp-day');
				} else {
					classes.push('dp-day-empty');
				}
				if (cell && cell.date === this.curDate) {
					classes.push('today');
				}
				if (cell && cell.date === this.val && !this.value) {
					classes.push('chosen');
				}
				if (cell && cell.date === String(this.val || '').slice(0, 10)) {
					classes.push('chosen');
				}
				return classes;
			},
			setVal(date) {
				this.$emit('input', date);
				this.$emit('change', {
					isTrusted: true,
					value: date
				});
			},
			clear() {
				this.setVal(this.emptyVal || '');
				this.hideCalendar();
			},
			onNativeValChanged() {
				this.setVal(this.nativeVal);
			},
			restrictNumber(e, min, max) {
				let val = e.target.value;
				if (isNaN(val) || val === '') {
					e.target.value = '';
				} else if (val < min) {
					e.target.value = min;
				} else if (val > max) {
					e.target.value = max;
				}
			}
		},
		watch: {
			value() {
				this.updateDatetimeData();
				this.nativeVal = this.value;
			},
			year() {
				if (this.year) {
					if (this.year < this.minYear) {
						this.year = this.minYear;
					} else if (this.year > this.maxYear) {
						this.year = this.maxYear;
					}
				}
				else {
					this.year = moment().format('YYYY');
				}
			},
			month() {
				this.month = this.month ? this.month :moment().format('MM');
			}
		},
		created() {
			this.updateDatetimeData();

			this.nativeVal = this.value;
		}
	};
</script>