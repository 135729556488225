import Index from '../pages/Index.vue';
import NotFound from '../pages/NotFound.vue';

export default {
	'semalt': [
		{
			path: '/',
			component: Index,
			meta: {sidebar: false, title: 'Semalt'}
		},
		{
			path: '/audit/:projectId',
			component: () => import('../pages/Audit.vue'),
			meta: {sidebar: true, title: 'Audit'}
		},
		{
			path: '/dashboard',
			component: () => import('../pages/Dashboard.vue'),
			meta: {sidebar: true, title: 'Dashboard'}
		},
		{
			path: '/developer',
			component: () => import('../pages/Developer.vue'),
			meta: {sidebar: true, title: 'Developer'}
		},
		{
			path: '/ecommerce',
			component: () => import('../pages/ECommerce.vue'),
			meta: {sidebar: true, title: 'E-commerce'}
		},
		{
			path: '/project/:projectId',
			component: () => import('../pages/Project.vue'),
			meta: {sidebar: true, title: 'Project'}
		},
		{
			path: '/settings',
			component: () => import('../pages/Settings.vue'),
			meta: {sidebar: true, title: 'Settings'}
		},
		{
			path: '/site/:site?',
			component: () => import('../pages/Site.vue'),
			meta: {sidebar: true, title: 'Site'}
		},
		{
			path: '/support',
			component: () => import('../pages/Support.vue'),
			meta: {sidebar: true, title: 'Support'}
		},
		{
			path: '/tools',
			component: () => import('../pages/Tools.vue'),
			meta: {sidebar: true, title: 'Tools'}
		},
		{
			path: '/aboutus',
			component: () => import('../pages/AboutUs.vue'),
			meta: {sidebar: false, title: 'About us'}
		},
		{
			path: '/autoseo',
			component: () => import('../pages/Autoseo.vue'),
			meta: {sidebar: false, title: 'Autoseo'}
		},
		{
			path: '/fullseo',
			component: () => import('../pages/Fullseo.vue'),
			meta: {sidebar: false, title: 'Fullseo'}
		},
		{
			path: '/ssl',
			component: () => import('../pages/Ssl.vue'),
			meta: {sidebar: false, title: 'SSL'}
		},
		{
			path: '/amazon',
			component: () => import('../pages/Amazon.vue'),
			meta: {sidebar: false, title: 'Amazon'}
		},
		{
			path: '/analytics',
			component: () => import('../pages/Analytics.vue'),
			meta: {sidebar: false, title: 'Analytics'}
		},
		{
			path: '/franchise',
			component: () => import('../pages/Franchise.vue'),
			meta: {sidebar: false, title: 'Franchise'}
		},
		{
			path: '/testimonials',
			component: () => import('../pages/Testimonials.vue'),
			meta: {sidebar: false, title: 'Testimonials'}
		},
		{
			path: '/test-playground',
			component: () => import('../pages/TestPlayground.vue'),
			meta: {sidebar: false, title: 'Test'}
		},
		{
			path: '/payment-history',
			component: () => import('../pages/PaymentHistory.vue'),
			meta: {sidebar: true, title: 'Payment History'}
		},
		{
			path: '/video-production',
			component: () => import('../pages/VideoProduction.vue'),
			meta: {sidebar: true, title: 'Video Production'}
		},
		{
			path: '*',
			component: NotFound,
			meta: {sidebar: false, title: 'Page not found'}
		}
	],
	'semalt#json': [
		{
			path: '/',
			component: () => import('../subdomains/Json.vue'),
			meta: {sidebar: false, title: 'JSON beautifier'}
		}
	],
	'semalt#timestamp': [
		{
			path: '/',
			component: () => import('../subdomains/Timestamp.vue'),
			meta: {sidebar: false, title: 'Timestamp converter'}
		}
	],
	'semalt#urlparser': [
		{
			path: '/',
			component: () => import('../subdomains/UrlParser.vue'),
			meta: {sidebar: false, title: 'URL parser'}
		}
	],
	'semalt#redirect': [
		{
			path: '/',
			component: () => import('../subdomains/Redirect.vue'),
			meta: {sidebar: false, title: 'Redirect'}
		}
	],
	'semalt#md5': [
		{
			path: '/',
			component: () => import('../subdomains/MD5.vue'),
			meta: {sidebar: false, title: 'MD5'}
		}
	],
	'semalt#geo': [
		{
			path: '/',
			component: () => import('../subdomains/Geo.vue'),
			meta: {sidebar: false, title: 'Geo'}
		}
	],
	'semalt#color': [
		{
			path: '/',
			component: () => import('../subdomains/ColorConverter.vue'),
			meta: {sidebar: false, title: 'Color converter'}
		}
	]
}