<template>
	<div class="serp">
		<div class="ph0 m-bottom10">
			<div class="p-bottom10 m-right10 clearfix">
				<div class="serp-stat left">
					<em v-if="totalCnt !== null">Found: {{ totalCnt }}</em>
					<em class="m-left10" v-if="checkedRows.length > 0">Checked: {{ checkedRows.length }}</em>
				</div>
				<div class="right">
					<div class="control-box m-right10">
						<i class="control-search fa fa-search"></i>
						<input type="text" v-model="app.query.keyword" @change="onControlChanged" placeholder="Keywords filter">
					</div>
					<div class="left m-left10 m-right30 lh30">
						<label class="chb">
							<input
								type="checkbox"
								v-model="app.query.kwExact"
								:true-value="1"
								:false-value="void 0"
								@change="onControlChanged"
							>
							<span class="upper">Exact</span>
						</label>
					</div>
					<div class="control-box m-right10">
						<label>Top</label>
						<base-rich-sel
							:items="getTopItems()"
							v-model="app.query.top"
							width="50"
							dd-width="100"
							no-search="1"
							@change="onControlChanged"
						></base-rich-sel>
					</div>
					<div class="control-box m-right10">
						<label>Dynamics</label>
						<base-rich-sel
							:items="getDynamicsItems()"
							v-model="app.query.dynamics"
							width="100"
							dd-width="120"
							no-search="1"
							@change="onControlChanged"
						></base-rich-sel>
					</div>
					<div class="control-box m-right10">
						<label>Sort</label>
						<base-rich-sel
							:items="getSortItems()"
							v-model="app.query.sort"
							width="120"
							dd-width="120"
							no-search="1"
							@change="onControlChanged"
						></base-rich-sel>
					</div>
					<div class="control-btn filled m-left10"><i class="fa fa-fw fa-plus"></i> Add keywords</div>
					<div class="control-btn m-left10" @click="showExport"><i class="fa fa-fw fa-download"></i></div>
				</div>
			</div>
			<div class="relative box padding0">
				<div class="loading" v-show="isLoading">
					<div class="loading-inner">
						<i class="fa fa-spinner spin-fast"></i>
						Loading...
					</div>
				</div>
				<transition name="fade">
					<div class="serp-overlay" v-if="exportShown">
						<div class="site-report">
							<div class="row xs-8 xl-24">
								<div class="fieldset col">
									<div class="fieldset-label"><i class="fa fa-fw fa-file-pdf-o"></i> PDF report</div>
									<div class="site-report-btns">
										<base-btn @click.native="downloadReport('pdf')" :loading="reportLoading.pdf">
											<i class="fa fa-fw fa-download" slot="icon"></i>
											<span>Download</span>
										</base-btn>
									</div>
								</div>
								<div class="fieldset col">
									<div class="fieldset-label"><i class="fa fa-fw fa-table"></i> CSV report</div>
									<div class="site-report-opts">
										<div v-for="col in reportCols" :key="col">
											<label class="chb">
												<input type="checkbox" v-model="reportKeys.csv[col]">
												<span>{{ col }}</span>
											</label>
										</div>
									</div>
									<div class="site-report-btns">
										<base-btn @click.native="downloadReport('csv')" :loading="reportLoading.csv">
											<i class="fa fa-fw fa-download" slot="icon"></i>
											<span>Download</span>
										</base-btn>
									</div>
								</div>
								<div class="fieldset col">
									<div class="fieldset-label"><i class="fa fa-fw fa-file-text-o"></i> TXT report</div>
									<div class="site-report-opts">
										<div v-for="col in reportCols" :key="col">
											<label class="chb">
												<input type="checkbox" v-model="reportKeys.txt[col]">
												<span>{{ col }}</span>
											</label>
										</div>
									</div>
									<div class="site-report-btns">
										<base-btn @click.native="downloadReport('txt')" :loading="reportLoading.txt">
											<i class="fa fa-fw fa-download" slot="icon"></i>
											<span>Download</span>
										</base-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<table class="serp-tbl" :style="{opacity: isLoading ? 0.3 : 1}">
					<thead>
					<tr>
						<th v-if="!simple" class="chb-cell">
							<label class="chb">
								<input type="checkbox" v-model="allChecked" @click="onAllChbClick">
							</label>
						</th>
						<th class="text-center serp-num-cell">
							<div class="head-cell-inner nowrap">#</div>
						</th>
						<th class="serp-kw-head text-left">
							<div class="head-cell-inner nowrap">
								<span @click="doSort('kw')" class="general-upper">Keyword</span>
							</div>
						</th>
						<th class="text-left">
							<div class="head-cell-inner nowrap">
								<span @click="doSort('url')" class="general-upper">URL</span>
							</div>
						</th>
						<th v-for="(date, i) in realDates">
							<div class="head-cell-inner nowrap">
								<span @click="doSort('pos' + (i !== dates.length - 1 ? date : ''))">{{ date }}</span>
							</div>
						</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="(row, i) in handledRows">
						<tr>
							<td class="chb-cell" v-if="!simple">
								<label class="chb">
									<input type="checkbox" v-model="row.checked" @click="onKeywordChbClick($event, i)">
								</label>
							</td>
							<td class="text-center serp-num-cell">{{ i + 1 }}</td>
							<td>
									<span class="serp-kw" @click="onKeywordClick(row)">
										<span v-if="kw" v-html="getHilitedKw(row.kw)"></span>
										<template v-else>{{ row.kw }}</template>
									</span>
							</td>
							<td class="serp-url-cell">
								<a :href="getPageUrl(row)" target="_blank" rel="noreferrer noorigin">{{ '/' + row.page }}</a>
							</td>
							<td class="serp-pos-cell" v-for="(pos, j) in row.pos">
									<span
										class="serp-pos"
										v-if="pos && realDates[j] !== '-'"
										:style="getPosStyle(pos)"
									>{{ pos !== 200 ? pos : 0 }}</span>
							</td>
						</tr>
						<tr v-if="row.serpShown" class="kw-serp-row">
							<td colspan="100">
								<template v-if="row.serpRows">
									<div v-for="row in row.serpRows" class="serp-inner-row">
										<div class="serp-inner-pos">{{ row.pos }}.</div>
										<div class="serp-inner-url">
											<a :href="getFullUrl(row)" class="visitable" target="_blank" rel="noreferrer noorigin">
												<span>{{ row.https ? 'https' : 'http' }}://{{ row.www ? 'www.' : '' }}</span><strong>{{ row.site }}</strong>/<span>{{ row.page }}</span>
											</a>
										</div>
									</div>
								</template>
								<div v-else>Loading...</div>
							</td>
						</tr>
					</template>
					</tbody>
					<tfoot>
					<tr v-show="isLoadingMore">
						<td colspan="100" class="text-center">
							<i class="fa fa-spinner spin-fast"></i>
							<span class="general-upper">Loading...</span>
						</td>
					</tr>
					<tr v-show="!isLoading && !isLoadingMore && isNoMore">
						<td colspan="100" class="text-center general-upper">
							{{ rows && rows.length ? 'No more data' : 'No data' }}
							</td>
					</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VueCookies from 'vue-cookies';
	import axios from 'axios';

	Vue.use(VueCookies);

	let posDiff = {
		template: `
		<span class="serp-diff" v-if="diff" :class="diff > 0 ? 'good' : 'bad'">
			<template v-if="diff > 0">&#x25B2;</template>
			<template v-else-if="diff < 0">&#x25BC;</template>{{ diff | abs }}
		</span>
	`,
		props: ['row', 'index'],
		computed: {
			diff() {
				if (!this.index) return '';

				let pos = this.row.pos[this.index];
				let prevPos = this.row.pos[this.index - 1];

				if (pos === 200 || prevPos === 200) return '';

				let diff = prevPos - pos;
				if (!diff) return '';

				return diff;
			}
		}
	};


	export default {
		components: {
			'pos-diff': posDiff
		},
		props: ['site', 'se', 'kw', 'kw-is-exact', 'sort', 'max-pos', 'dates', 'period', 'all', 'simple', 'mode'],
		data() {
			return {
				rows: [],
				posDates: [],
				isLoading: false,
				isLoadingMore: false,
				isNoMore: false,
				sortIdx: null,
				sortDir: 1,
				kwPopupShown: false,
				advPopupShown: false,
				totalTime: null,
				serverTime: null,
				totalCnt: null,
				scrollId: null,
				kwFilter: '',
				kwExact: false,
				allChecked: false,
				lastChbIdx: null,
				exportShown: false,

				reportLoading: {
					txt: false,
					csv: false,
					pdf: false
				},
				reportKeys: {
					txt: {},
					csv: {},
					pdf: {},
				},
			};
		},
		computed: {
			handledRows() {
				return this.rows || [];
			},
			checkedRows() {
				return this.handledRows.filter(row => row.checked);
			},
			realDates() {
				if (this.posDates && this.posDates.length) return this.posDates;
				return this.dates || [];
			},
			loader() {
				return [
					this.site,
					this.se,
					this.sort,
					this.kw,
					this.kwIsExact,
					this.maxPos,
					this.dates,
					this.period,
					this.mode
				].join('*');
			},
			reportCols() {
				return ['keyword', 'url', ...this.dates];
			}
		},
		methods: {
			loadData() {
				if (!this.site) return;

				this.isLoading = true;
				this.isNoMore = false;
				this.totalTime = null;
				this.serverTime = null;
				let startTime = Date.now();

				this.totalCnt = null;
				this.scrollId = null;

				this.lastChbIdx = null;

				let params = {
					site: this.site,
					se: this.se,
					dates: this.dates.join(','),
					sort: this.sort,
					kw: this.kw,
					kwExact: this.kwExact ? 1 : 0,
					maxPos: this.maxPos,
					all: this.all && this.all !== '0' ? 1 : 0,
					period: this.period,
					mode: this.mode
				};
				let cancelToken = this.tokens('serp');
				axios('/api/serp', {params, cancelToken}).then(res => {
					this.isLoading = false;
					this.totalTime = (Date.now() - startTime) / 1000;
					this.serverTime = parseFloat(res.headers['x-request-time']);
					this.scrollId = res.data.scrollId;

					console.log(res.data.times);

					this.totalCnt = res.data.total;

					this.posDates = res.data.dates;

					this.rows = [];
					if (res.data.rows.length) {
						this.appendRows(res.data.rows);
					} else {
						this.isNoMore = true;
					}
				});
			},
			appendRows(rows) {
				if (!rows || !rows.length) return;

				rows.forEach(row => {
					row.pos.forEach((pos, i) => {
						if (!pos) {
							row.pos[i] = 200;
						}
					});
					row.checked = this.allChecked;
					row.serpShown = false;
					row.serpRows = null;
				});
				this.rows = this.rows.concat(rows);
			},
			showExport() {
				this.exportShown = !this.exportShown;
			},
			downloadReport(type) {
				let cookieParam = 'report_' + type + '_loading_' + Date.now();
				let map = this.reportKeys[type];
				let cols = Object.keys(map).filter(key => map[key]).map(key => key).join(',');

				let qs = this.buildQueryString({
					type,
					cookieParam,
					cols,

					site: this.app.params.site,
					se: this.app.query.se,

					sort: this.app.query.sort,
					kw: this.app.query.keyword,
					kwExact: this.kwExact == 1 ? 1 : 0,
					maxPos: this.app.query.top,
					dates: this.dates.join(','),
					period: this.app.query.period
				});

				let frame = document.createElement('iframe');
				frame.style.display = 'none';
				document.body.appendChild(frame);
				frame.src = '/api/serp/report?' + qs;

				this.$set(this.reportLoading, type, true);
				let expTime = 120;
				this.$cookies.set(cookieParam, '1', expTime, '/');
				let intv = setInterval(() => {
					let cookieVal = this.$cookies.get(cookieParam);
					if (cookieVal) return;

					this.$set(this.reportLoading, type, false);
					clearInterval(intv);
				}, 100);
			},
			handleReportKeys() {
				Object.keys(this.reportKeys).forEach(type => {
					let map = this.reportKeys[type];

					Object.keys(map).forEach(key => {
						if (this.reportCols.indexOf(key) === -1) {
							this.$delete(map, key);
						}
					});
					this.reportCols.forEach(col => {
						if (map[col] == null) {
							this.$set(map, col, true);
						}
					});
				});
			},
			onAllChbClick(e) {
				this.handledRows.forEach(row => {
					this.$set(row, 'checked', e.target.checked);
				});
			},
			onKeywordChbClick(e, idx) {
				if (!e.checked) {
					this.allChecked = false;
				}
				if (e.shiftKey && this.lastChbIdx !== null) {
					let startIdx, endIdx;
					if (idx > this.lastChbIdx) {
						startIdx = this.lastChbIdx;
						endIdx = idx;
					} else {
						startIdx = idx;
						endIdx = this.lastChbIdx + 1;
					}
					this.handledRows.slice(startIdx, endIdx).forEach(row => {
						this.$set(row, 'checked', e.target.checked);
					});
				}
				this.lastChbIdx = idx;
			},
			showKeywordsChart() {
				let keywords = this.checkedRows.map(row => row.kw);

				let hash = 'kwchart_' + keywords.map(kw => this.urlEnc(kw, true)).join(',');
				this.navigateHash(hash);
			},
			getFullUrl(row) {
				let url = '';
				url += row.https ? 'https' : 'http';
				url += '://';
				url += row.www ? 'www.' : '';
				url += row.site;
				url += '/';
				url += row.page;
				return url;
			},
			getPageUrl(row) {
				let prop = String(row.prop);
				if (prop.length < 2) {
					prop = '0' + prop;
				}
				let https = prop[0] === '1';
				let www = prop[1] === '1';

				let url = '';
				url += https ? 'https://' : 'http://';
				url += www ? 'www.' : '';
				url += this.getDomainFromUrl(this.site);
				url += '/' + row.page;

				return url;
			},
			getHilitedKw(kw) {
				let regexStr = this.kw.toLowerCase()
					.split(/\s+/)
					.filter(word => word.trim())
					.map(word => this.escapeRegex(word))
					.join('|');
				let regex = new RegExp('(\\b' + regexStr + '\\b)', 'g');

				kw = kw.replace(regex, '<span class="hilite">$1</span>');

				return kw;
			},
			onKwChanged() {
				this.$emit('kwfilterchanged', {
					kwFilter: this.kwFilter,
					kwExact: this.kwExact
				});
			},
			getTopItems() {
				let items = [
					{key: '1', val: '1'},
					{key: '3', val: '3'},
					{key: '10', val: '10'},
					{key: '20', val: '20'},
					{key: '30', val: '30'},
					{key: '50', val: '50'},
					{key: '100', val: '100'},
				];
				return items;
			},
			getDynamicsItems() {
				let items = [
					{key: 'no_positions', val: 'No positions'},
					{key: 'changed', val: 'Changed'},
					{key: 'not_changed', val: 'Not changed'},
					{key: 'moved_up', val: 'Moved up'},
					{key: 'moved_down', val: 'Moved down'},
					{key: 'entered', val: 'Entered'},
					{key: 'dropped', val: 'Dropped'}
				];
				return items;
			},
			getSortItems() {
				let items = [
					{key: 'pos', val: 'Position 1-100'},
					{key: '-pos', val: 'Position 100-1'},
					{key: 'kw', val: 'Keyword A-Z'},
					{key: '-kw', val: 'Keyword Z-A'},
					{key: 'url', val: 'URL A-Z'},
					{key: '-url', val: 'URL Z-A'}
				];
				return items;
			},
			loadMore() {
				if (this.isLoading || this.isLoadingMore || this.isNoMore || !this.scrollId) return;

				this.isLoadingMore = true;

				let params = {
					site: this.site,
					se: this.se,
					dates: this.realDates.join(','),
					sort: this.sort,
					scrollId: this.scrollId,
					period: this.period,
					mode: this.mode
				};
				let cancelToken = this.tokens('sero');
				axios('/api/serp', {params, cancelToken}).then(res => {
					this.isLoadingMore = false;

					console.log(res.data.times);

					if (res.data.rows.length) {
						this.appendRows(res.data.rows);
					} else {
						this.isNoMore = true;
					}
				});
			},
			doSort(sort) {
				if (sort === this.sort) {
					sort = '-' + sort;
				}
				this.$emit('sortchosen', {sort});
			},
			onKeywordClick(row) {
				this.$set(row, 'serpShown', !row.serpShown);
				if (!row.serpShown) {
					row.serpRows = null;
					return;
				}

				let cancelToken = this.tokens('serp_kw_' + row.kw);
				let kw = row.kw;
				let se = this.se || 1;
				let params = {kw, se};

				axios('/api/serp/kw-results', {params, cancelToken}).then(res => {
					if (!row.serpShown) return;

					this.$set(row, 'serpRows', res.data.rows);
				});
			},
			getSortByPosFn(idx) {
				return (a, b) => {
					if (a.pos[idx] > b.pos[idx]) return this.sortDir;
					if (a.pos[idx] < b.pos[idx]) return -this.sortDir;
					return 0;
				}
			},
			sortByKw(a, b) {
				if (a.kw > b.kw) return this.sortDir;
				if (a.kw < b.kw) return -this.sortDir;
				return 0;
			},
			toggleAdvPopup() {
				this.advPopupShown = !this.advPopupShown;
			},
			toggleKwPopup() {
				this.kwPopupShown = !this.kwPopupShown;
			}
		},
		watch: {
			loader() {
				this.loadData();
			},
			kw() {
				this.kwFilter = this.kw;
			},
			kwIsExact() {
				this.kwExact = this.kwIsExact && this.kwIsExact !== '0';
			},
			reportCols() {
				this.handleReportKeys();
			}
		},
		mounted() {
			this.kwFilter = this.kw;
			this.kwExact = this.kwIsExact && this.kwIsExact !== '0';
			this.loadData();
		}
	}
</script>

<style lang="less">
	.serp {
		position: relative;
		width: 100%;

		table.serp-tbl {
			width: 100%;
			border-collapse: collapse;

			th, td {
				padding: 5px 10px;
				border-bottom: 1px solid #ddd;
			}

			th {
				color: #999;
				border-top: 1px solid #ccc;
				background: #f2f3f8;
			}

			tr:hover td {
				background: #fafbfd;
			}
		}

		.serp-stat {
			position: relative;
			margin: 20px 0 0 0;
		}

		.chb-cell {
			text-align: left;
			padding-left: 10px !important;
			width: 30px;
		}

		.serp-num-cell {
			max-width: 20px;
		}

		.serp-url-cell {
			max-width: 250px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.serp-pos-cell {
			position: relative;
			text-align: center;
			width: 90px;
		}

		.serp-pos {
			display: inline-block;
			width: 36px;
			line-height: 22px;
		}

		.serp-diff {
			position: absolute;
			left: 50%;
			margin: 0 0 0 22px;
			font-size: 10px;

			&.good {
				color: #16A005;
			}
			&.bad {
				color: #f00;
			}
		}

		.serp-kw-head {
			min-width: 300px;
		}

		.head-cell-inner span {
			cursor: pointer;
		}

		.serp-kw {
			cursor: pointer;
		}

		.site-report {
			margin: 100px auto 0;
			padding: 20px 10px 10px;
			width: 500px;
			max-width: 95%;
			background: #fff;

			.box {
				padding-top: 20px;
				padding-bottom: 15px;

				/*.fieldset {
					margin: 20px 0 0;

					&:nth-child(1) {
						margin: 0;
					}
				}*/
			}

			.site-report-btns a {
				font-size: 16px;
				color: #555;

				&:hover {
					color: #222;
				}
			}

			.site-report-opts {
				padding: 0 0 15px;

				div {
					padding: 1px 0;
				}
			}

			.chb span {
				text-transform: uppercase;
			}
		}
	}
</style>