export default {
	topPages: [
		{
			url: '/dashboard',
			label: 'Dashboard'
		},
		{
			url: '/pricing',
			label: 'Pricing'
		},
		{
			url: '/support',
			label: 'Support'
		},
		{
			url: '/products',
			label: 'Products',
			sub: [
				{url: '/autoseo',label: 'AutoSEO'},
				{url: '/fullseo',label: 'FullSEO'},
				{url: '/analytics',label: 'Analytics'},
				{url: '/franchise',label: 'Franchise'}
			]
		},
		{
			url: '/',
			label: 'Success Stories'
		},
		{
			url: '/',
			label: 'Semalt'
		},
		{
			url: '/aboutus',
			label: 'About us'
		}
	],
	sidebarPages: [
		{
			url: '/site/semalt.com',
			label: 'Rankings',
			cls: 'fa fa-fw fa-line-chart'
		},
		{
			url: '/reports',
			label: 'Report center',
			cls: 'fa fa-fw fa-calendar'
		},
		{
			label: 'API',
			cls: 'fa fa-fw fa-wrench',
			subShown: false,
			sub: [
				{
					url: '/developer',
					label: 'Introducation'
				},
				{
					url: '/developer',
					label: 'Documentation / Guide'
				},
				{
					url: '/developer',
					label: 'Getting started'
				},
				{
					url: '/developer',
					label: 'Prices and Plans'
				},
				{
					url: '/developer',
					label: 'FAQ'
				}
			]
		},
		{
			url: '/tools',
			label: 'Free services',
			cls: 'fa fa-fw fa-gears'
		},
		{
			url: '/settings',
			label: 'Settings',
			cls: 'fa fa-fw fa-cog',
			requireAuth: true
		}
	]
};