var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.getClasses(),
      attrs: {
        type: _vm.submit != null ? "submit" : "button",
        disabled: _vm.disabled || _vm.loading
      },
      on: {
        click: _vm.onClick,
        mousedown: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      !_vm.loading ? _c("span", [_vm._t("icon")], 2) : _vm._e(),
      _vm._v(" "),
      _c("span", [
        _vm.loading
          ? _c("i", { staticClass: "fa fa-fw fa-spinner spin-fast" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }