<template>
	<div ref="rangeDatePicker" class="rdp" v-mousedown-outside="hideCalendar">
		<div class="dp-tf-box">
			<template v-if="app.win.width >= app.win.SM">
				<span v-if="!unclearable" v-show="value" class="dp-clear zmdi zmdi-close" @click="clear"></span>
				<input type="text" :value="tfText" readonly @focus="showCalendar">
			</template>
			<input v-else type="date" @change="onNativeValChanged">
		</div>
		<div v-if="calendarShown" class="clalendars-show" :style="'right: ' + posRight">
			<div class="calendar-wrap">
				<base-calendar v-model="from" class="calendar-from"></base-calendar>
				<base-calendar v-model="to" class="calendar-to"></base-calendar>
			</div>
			<div class="picker-bottom">
				<div v-if="error" class="error-message">
					<i class="fa fa-exclamation-triangle"></i>
					<span>{{ error }}</span>
				</div>
				<div class="clearfix">
					<div class="right">
						<span v-for="item in presets" class="pic-link m-left10" @click="applyPreset(item.val)">
							<span>{{ item.text }}</span>
						</span>
						<span class="pic-link m-left10" @click="onNativeValChanged">
							<i class="fa fa-check"></i>
							<span>Apply</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/var";
	@import "../styles/mixin";

	.rdp {
		.dp-tf-box {

			position: relative;

			input {
				width: 100%;
				cursor: text;
			}
		}

		.dp-clear {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
			z-index: 1;
		}

		.clalendars-show {
			background: #fff;
			left: 0;

			.calendar-wrap {
				.flexbox();
			}

			position: absolute;
			z-index: 100;
			.box-shadow();


			.picker-bottom {
				min-height: 23px;
				padding: 5px 7px 4px 5px;

				span {
					.main-font;
				}

				.error-message {
					padding: 2px 0 4px;
					color: @danger;
					text-align: center;
				}

				.presets {
					position: relative;
					top: -2px;
					margin-right: 15px;
				}

				.apply {
					margin-left: 10px;
				}
			}
		}

		.calendar-to {
			margin-left: 1px;
		}
	}
</style>

<script>
	import moment from 'moment';

	export default {
		props: ['value', 'unclearable', 'empty-val', 'empty-text', 'presets'],
		data() {
			return {
				from: null,
				to: null,
				defaultFrom: null,
				defaultTo: null,
				error: '',

				calendarShown: false,
				weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
				months: [
					'January', 'February', 'March',
					'April', 'May', 'June',
					'July', 'August', 'September',
					'October', 'November', 'December'
				],
				curDate: moment().format('YYYY-MM-DD'),
				curTime: moment().format('HH:mm')
			};
		},
		computed: {
			tfText() {
				if (this.emptyVal && this.emptyText && this.value === this.emptyVal) return this.emptyText;
				if (this.value && this.value.length !== 21) this.clear();
				if (!this.value) return this.value;

				let year = moment().format('YYYY');
				let formattedText = this.value.split('_').map(date => {
					let d = moment(date);
					if (d.format('YYYY') === year) {
						return d.format('MMM DD');
					}
					return d.format('MMM DD, YYYY');
				}).join(' - ');

				return formattedText;
			},
			val() {
				if (this.from && this.to) return this.from + '_' + this.to;
				if (this.from) return this.from + '_';
				if (this.to) return '_' + this.to;
				if (this.value) return this.value;
				return '_';
			},
			posRight() {
				let pos = this.$refs.rangeDatePicker.getBoundingClientRect().x;
				let win = this.getWinWidth();
				return pos > win/2 ? '10px' : null;
			}
		},
		methods: {
			showCalendar() {
				this.calendarShown = true;
			},
			hideCalendar() {
				// if (this.from > this.to) {
				// 	this.from = '';
				// 	this.to = '';
				// 	this.defaultFrom = '';
				// 	this.defaultTo = '';
				// 	this.val = '';
				// 	this.error = '';
				// 	this.setVal(this.emptyVal || '');
				// }
				this.calendarShown = false;
			},
			setVal(date) {
				this.$emit('input', date);
				this.$emit('change', {
					isTrusted: true,
					value: date
				});
			},
			clear() {
				this.from = '';
				this.to = '';
				this.defaultFrom = '';
				this.defaultTo = '';
				this.val = '';
				this.error = '';
				this.setVal(this.emptyVal || '');
				this.hideCalendar();
			},
			onNativeValChanged() {
				if (!this.from) this.from = this.curDate;
				if (!this.to) this.to = this.curDate;
				if (this.from > this.to) {
					this.error = `Start date can not be more than the end date`;
					return;
				}
				this.error = '';
				this.setVal(this.val);
				this.hideCalendar();
			},
			applyPreset(val) {
				let [from, to] = val.split('_');
				this.from = from;
				this.to = to;
			},
			chooseCurDay() {
				this.from = this.curDate;
				this.to = this.curDate;
				this.error = '';
				this.setVal(this.val);
				this.hideCalendar();
			}
		},
		watch: {
			val() {
				this.from = this.val.split('_')[0];
				this.to = this.val.split('_')[1];
			}
		}
	};
</script>