import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';

let v = new Vue({
	el: '#app',
	render: h => h(App)
});

axios('/config').then(res => App.methods.init.call(v.app, res.data));

window.v = v;