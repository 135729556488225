<template>
	<div class="the-sidebar" :class="minimized ? 'minimized' : ''">
		<ul class="sidebar-prim-list">
			<template v-for="page in pages" v-if="loggedIn || !page.requireAuth">
				<li class="sidebar-prim-item">
					<base-link
						:href="page.url || ''"
						v-title.right="minimized ? page.label : ''"
						@click.native="onPageClick($event, page)"
					>
						<i class="sidebar-item-icon" :class="page.cls"></i>
						<span>{{ page.label }}</span>
						<i v-if="page.sub && !minimized" class="sidebar-item-arrow fa fa-chevron-down"></i>
					</base-link>
					<ul class="sidebar-sec-list" v-if="page.subShown">
						<li v-for="(subPage, j) in page.sub" class="sidebar-sec-item">
							<base-link :href="subPage.url">
								<span></span>
								{{ subPage.label }}
							</base-link>
						</li>
					</ul>
				</li>
			</template>
		</ul>

		<div class="sidebar-bottom" @click="toggleSidebar">
			<i class="fa fa-fw fa-chevron-left"></i>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/mixin";

	.the-sidebar {
		position: fixed;
		padding: 80px 0 0;
		width: @sidebarWidth;
		height: 100%;
		background: #fff;
		z-index: 2;
		.box-shadow();
		.transition(all 0.2s);

		&.minimized {
			width: @sidebarMinimWidth;

			ul li a {
				span, i.sidebar-item-arrow {
					visibility: hidden;
					opacity: 0;
				}
			}

			ul.sidebar-sec-list {
				position: absolute;
				top: 0;
				left: 100%;
				background: #fff;
				border: 1px solid #ccc;

				li {
					padding-left: 0;

					a:hover {
						background: #efefef;
					}
				}
			}

			.sidebar-bottom i {
				.transform(rotate(180deg));
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 14px;

			&.sidebar-prim-list {
				border-top: 1px solid #dedede;
			}

			li {
				position: relative;
				margin: 0;
				display: block;
				white-space: nowrap;

				a {
					position: relative;
					display: block;
					padding: 8px 10px 8px 10px;
					color: #000;
					font-weight: 500;
					text-decoration: none !important;
					overflow: hidden;

					i.sidebar-item-icon {
						margin-right: 7px;
						opacity: 0.5;
					}
					span {
						visibility: visible;
						opacity: 1;
						.transition(all 0.2s);
					}
					i.sidebar-item-arrow {
						position: absolute;
						right: 7px;
						top: 50%;
						margin-top: -8px;
						opacity: 0.5;
						visibility: visible;
						.transition(all 0.2s);

						&:hover {
							opacity: 1;
						}
					}
				}
			}

			li.sidebar-prim-item > a {
				border-bottom: 1px solid #dedede;

				&:hover {
					background: #efefef;

					i.sidebar-item-icon {
						opacity: 1;
					}
				}
			}

			li.sidebar-sec-item {
				padding-left: 25px;

				a {
					padding: 8px 10px 8px 15px;
					font-weight: normal;
					font-size: 13px;

					span {
						display: none;
						position: absolute;
						left: 0;
						top: 5px;
						width: 3px;
						height: 80%;
						background: #51a0d5;
						border-radius: 2px;
					}

					&:hover span {
						display: block;
					}
				}
			}
		}

		.sidebar-bottom {
			@h: 40px;

			position: absolute;
			width: 100%;
			height: @h;
			bottom: 0;
			background: #ccc;
			cursor: pointer;

			i {
				position: absolute;
				right: 0;
				width: 40px;
				height: @h;
				line-height: @h;
				font-size: 15px;
				text-align: center;
				color: #fff;

				.transform(rotate(0deg));
				.transition(all 0.2s);
			}
		}
	}
</style>

<script>
	export default {
		props: ['pages', 'logged-in', 'minimized'],
		methods: {
			onPageClick(e, page) {
				e.preventDefault();
				this.$set(page, 'subShown', !page.subShown);
			},
			toggleSidebar() {
				this.$emit('toggle');
			}
		}
	}
</script>