var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.cc
      ? _c("img", {
          directives: [
            {
              name: "title",
              rawName: "v-title",
              value: !_vm.noTitle ? _vm.countryName : null,
              expression: "!noTitle ? countryName : null"
            }
          ],
          class: "flag flag-" + _vm.geoNormalized,
          attrs: { src: "/img/pixel.png" }
        })
      : _c("span", { staticClass: "empty-flag" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }