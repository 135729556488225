import axios from 'axios';
import md5 from  'js-md5';
import moment from 'moment';

export default {
	emit(event, data = null) {
		if (!this.app.eventsMap[event]) return;

		this.app.eventsMap[event].forEach(fn => fn(data));
	},
	on(event, fn) {
		if (!this.app.eventsMap[event]) {
			this.app.eventsMap[event] = [];
		}
		this.app.eventsMap[event].push(fn);
	},
	alert(msg) {
		this.app.modalPopup.type = 'alert';
		this.app.modalPopup.msg = msg;

		let res;
		this.app.onModalAction = (e) => {
			res(e);
		};

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	md5(val) {
		if (!val) return '';
		return md5(val);
	},
	prompt(title, defaultVal, promptType) {
		this.app.modalPopup.type = 'prompt';
		this.app.modalPopup.msg = defaultVal;
		this.app.modalPopup.promptTitle = title;
		this.app.modalPopup.promptType = promptType;

		let res;
		this.app.onModalAction = (e) => {
			res(e);
		};

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	confirm(msg) {
		this.app.modalPopup.type = 'confirm';
		this.app.modalPopup.msg = msg;

		let res;
		this.app.onModalAction = (e) => {
			res(e);
		};

		return new Promise((resolve, reject) => {
			res = resolve;
		});
	},
	tokens(tokenName) {
		let tokens = this.app.cancelTokens;
		if (!tokens) return;

		if (!tokenName) return tokens;
		if (tokenName in tokens) {
			tokens[tokenName].cancel();
		}
		this.$set(tokens, tokenName, axios.CancelToken.source());
		return tokens[tokenName].token;
	},
	async loadGeneralInfo(keys) {
		let map = {
			user: 'User.getCur',
			serverTime: 'Misc.getServerTime',
			geoMap: 'Misc.getGeoMap',
			searchEngines: 'Serp.getSearchEngines'
		};
		let args = keys.map(key => [map[key]]);
		let results = await this.multiGet.apply(this, args);
		results.forEach((res, i) => {
			let key = keys[i];
			this.app[key] = res;
		});
	},
	async get(classMethod, ...args) {
		if (!this.app.rootDomain) return this.warn('Domain not ready ' + classMethod);

		let argsJson = JSON.stringify(args);
		let params = {argsJson};
		let url = 'https://' + this.app.rootDomain + '/api/call/' + classMethod;
		let res = await axios(url, {params, withCredentials: true});

		return res.data.res;
	},
	async multiGet(...portions) {
		if (!this.app.rootDomain) return this.warn('Domain not ready');

		let params = {portions: JSON.stringify(portions)};
		let url = 'https://' + this.app.rootDomain + '/api/call/multi';
		let res = await axios(url, {params, withCredentials: true});
		return res.data.res;
	},
	async post(classMethod, ...args) {
		if (!this.app.rootDomain) return this.warn('Domain not ready ' + classMethod);

		let argsJson = JSON.stringify(args);
		let data = {argsJson};
		let url = 'https://' + this.app.rootDomain + '/api/call/' + classMethod;
		let res = await axios.post(url, data, {withCredentials: true});
		return res.data.res;
	},
	log(...args) {
		if (!window.console || !console.log) return;
		console.log.apply(console, args);
	},
	warn(...args) {
		if (!window.console || !console.warn) return;
		console.warn.apply(console, args);
	},
	setLang(lang) {
		if (!this.loadedLangsMap[lang]) {
			this.loadLangTexts(lang);
		}
		this.$store.commit('setLang', lang);
	},
	loadLangTexts(sLangs = 'en', sTags = '') {
		sTags = (sTags + ',page:' + this.app.currentPage).trim(',');

		this.get('MultiLang.getTexts', sLangs, sTags).then(res => {
			this.$store.commit('addLangTexts', res);
		});
	},
	i(slug) {
		let txt = this.i18n[slug];
		if (!txt) {
			console.log('i18n missing:', slug);
			return slug;
		}

		return txt[this.language] || txt.en || slug;
	},
	getPosDatesPresets() {
		let today = moment().format('YYYY-MM-DD');

		let list = [
			{
				text: 'Last month',
				val: moment().add(-30, 'days').format('YYYY-MM-DD') + '_' + today
			},
			{
				text: 'Last weak',
				val: moment().add(-6, 'days').format('YYYY-MM-DD') + '_' + today
			}
		];
		return list;
	},
	parseJson(json, showError = false) {
		try {
			return JSON.parse(json);
		} catch (err) {
			if (showError) console.log(err);
			return null;
		}
	},
	logErr(...args) {
		window.console && console.error.apply(console, args);
	},
	rand(n) {
		return Math.floor(Math.random() * n);
	},
	compare(a, b) {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	},
	getLanguageByCode(code) {
		return this.langsMap[String(code).toLowerCase()];
	},
	getCountryByCode(code) {
		return this.app.geoMap[String(code).toUpperCase()];
	},
	getGeoItems() {
		if (!this.app.geoMap) return [];

		let items = Object.keys(this.app.geoMap).map(code => {
			let country = this.app.geoMap[code];
			let searchVal = code + '*' + country;
			if (code === 'GB') {
				searchVal += '*UK*Great Britain';
			} else if (code === 'GR') {
				searchVal += '*EL';
			} else if (code === 'US') {
				searchVal += '*USA*United states of America';
			} else if (code === 'NL') {
				searchVal += '*Holland';
			}

			return {
				key: code,
				val: code,
				sVal: searchVal,
				sortVal: country,
				html: `<div class="geo-suggestion">
						<img
							src="//semalt.com/img/pixel.png"
							class="flag flag-${code.toLowerCase()}"
						>
						<span>${this.app.geoMap[code]}</span>
					</div>`
			};
		});
		return items;
	},
	getCurrencyItems(opts = {}) {
		let fsItems = [
			{key: 'USD', cc: 'US', text: 'United States Dollar'},
			{key: 'EUR', cc: 'EU', text: 'Euro'},
			{key: 'AUD', cc: 'AU', text: 'Australian Dollar'},
			{key: 'CAD', cc: 'CA', text: 'Canadian Dollar'},
			{key: 'CHF', cc: 'CH', text: 'Swiss Franc'},
			{key: 'CLP', cc: 'CL', text: 'Chilean Peso'},
			{key: 'CNY', cc: 'CN', text: 'Chinese Yuan'},
			{key: 'COP', cc: 'CO', text: 'Columbian Peso'},
			{key: 'CZK', cc: 'CZ', text: 'Czech Republic Koruna'},
			{key: 'DKK', cc: 'DK', text: 'Danish Krone'},
			{key: 'GBP', cc: 'GB', text: 'Pounds Sterling'},
			{key: 'HKD', cc: 'HK', text: 'Hong Kong Dollar'},
			{key: 'INR', cc: 'IN', text: 'Indian Rupee'},
			{key: 'JPY', cc: 'JP', text: 'Japanese Yen'},
			{key: 'MXN', cc: 'MX', text: 'Mexican Peso'},
			{key: 'NZD', cc: 'NZ', text: 'New Zealand Dollar'},
			{key: 'PLN', cc: 'PL', text: 'Polish Zloty'},
			{key: 'RUB', cc: 'RU', text: 'Russian Ruble'},
			{key: 'SEK', cc: 'SE', text: 'Swedish Krona'},
			{key: 'SGD', cc: 'SG', text: 'Singapore Dollar'},
			{key: 'ZAR', cc: 'ZA', text: 'South African Rand'}
		];
		let connectumItems = [
			{key: 'USD', cc: 'US', text: 'United States Dollar'},
			{key: 'EUR', cc: 'EU', text: 'Euro'},
			{key: 'GBP', cc: 'GB', text: 'Pounds Sterling'}
		];
		let alipayItems = [
			{key: 'USD', cc: 'US', text: 'United States Dollar'},
			{key: 'CNY', cc: 'CN', text: 'Chinese Yuan'}
		];
		let rawItems = null;
		if (opts.list === 'fs') {
			rawItems = fsItems;
		} else if (opts.list === 'connectum') {
			rawItems = connectumItems;
		} else if (opts.list === 'alipay') {
			rawItems = alipayItems;
		} else {
			return [];
		}

		let items = rawItems.map(r => {
			let key = r.key;
			let val = r.key + ' - ' + r.text;
			let sVal = r.key + '*' + r.text;
			if (r.cc === 'GB') {
				sVal += '*UK*United Kingdom*Great Britain';
			} else if (r.cc === 'US') {
				sVal += '*USA*United states of America';
			}
			let html = `<div class="currency-suggestion">
					<img
						src="/img/pixel.png"
						class="flag flag-${r.cc.toLowerCase()}"
					>
					<strong>${r.key}</strong>
					<span>${r.text}</span>
				</div>`;
			let valHtml = `<div>
					<img src="/img/pixel.png" class="flag flag-${r.cc.toLowerCase()}">
					<span>${r.key}</span>
				</div>`;

			let item = {key, val, sVal, html, valHtml};
			return item;
		});

		return items;
	},
	getUrlBySite(site) {
		site = String(site || '').trim();
		if (!site) return;

		if (/^https?:\/\//i.test(site)) return site;
		if (site.indexOf('//') === 0) return site;
		return 'http://' + site;
	},
	setLoading(isLoading) {
		this.app.isLoading = !!isLoading;
	},
	formatDate(datetime, format) {
		return moment(datetime).format(format);
	},
	now(format = 'YYYY-MM-DD HH:mm:ss') {
		return moment().format(format);
	},
	switchDatetimeFormat() {
		this.$store.commit('switchDatetimeFormat');
	},
	getToolUrl(key) {
		return `//${key}.${this.app.rootDomain}`;
	},
	capitalize(val) {
		if (!val) return '';
		val = val.toString();
		return val.charAt(0).toUpperCase() + val.slice(1);
	},
	getChartFonts() {
		return 'Roboto, "Open Sans", sanf-serif, helvetica, tahoma, verdana';
	},
	getFsOrderUrl(orderRef) {
		return 'https://springboard.fastspring.com/order/search.xml?query=' + this.urlEnc(orderRef) + '&mRef=&cRef=';
	},
	editQueryParams(q) {
		let query = {...this.app.query, ...q};
		this.navigate({query});
	},
	onControlChanged(e, opts = {}) {
		if (!e.isTrusted) return;

		let query = this.app.query;
		for (let i in query) {
			if (!query.hasOwnProperty(i)) continue;
			if (query[i] === '' || query[i] == null) {
				delete query[i];
			}
		}

		let params = this.app.params;
		for (let i in params) {
			if (!params.hasOwnProperty(i)) continue;
			if (params[i] === '' || params[i] == null) {
				delete params[i];
			}
		}

		let hash = opts.keepHash ? this.app.hash : void 0;

		this.navigate({query, params, hash});
	},
	navigate(opts, isReplace = false) {
		let query = opts.query || {};
		let params = opts.params || {};

		let data = {...opts, query, params};
		let method = isReplace ? 'replace' : 'push';
		this.$router[method](data);
	},
	navigateHash(hash) {
		let query = this.$route.query || {};
		this.$router.push({query, hash});
	},
	navigateOutHash(isReplace = false) {
		let query = this.$route.query || {};
		this.$router[isReplace ? 'replace' : 'push']({query});
	},
	toKeyboardLatin(raw) {
		return raw.toLowerCase().replace(this.cyrToLatinCharRegex, char => {
			return this.cyrToLatinCharMap[char] || char;
		});
	},
	toPascalCase(str) {
		return str.replace(/[_-]+(.)/g, (s, c) => c.toUpperCase()).replace(/^(.)/, s => s.toUpperCase());
	},
	async loadPaymentPopup(price, opts = {}) {
		let params = {
			price,
			idu: opts.idu,
			currency: opts.currency
		};

		//let res = await this.get('Payment.getFsPopupParams', params);
		let res = await this.get('PaymentSystem/FastSpring.getPaymentPopupData', params);
		this.log(res);

		opts.onDataReceived && opts.onDataReceived();

		fastspring.builder.clean();
		if (typeof opts.geo === 'string') {
			fastspring.builder.country(opts.geo.toUpperCase());
		}
		fastspring.builder.secure(res.payload, res.key);
		fastspring.builder.push(res.data);
	},
	getPaymentTypesItems() {
		let items = [
			{key: 'order', val: 'Order'},
			{key: 'rebill', val: 'Rebill'},
			{key: 'order_failed', val: 'Order fail'},
			{key: 'payment_failed', val: 'Rebill fail'},
			{key: 'cancel', val: 'Cancel'},
			{key: 'refund', val: 'Refund'}
		];
		return items;
	},
	getPaymentTypeBySlug(slug) {
		let orderTypesMap = {
			'order': 'Order',
			'rebill': 'Rebill',
			'order_failed': 'Order fail',
			'payment_failed': 'Rebill fail',
			'cancel': 'Cancel',
			'refund': 'Refund'
		};
		return orderTypesMap[slug];
	},
	hideAllTitles() {
		this.app.hideAllTitles();
	},
	getSearchEngineById(seId, def) {
		return this.app.searchEngines.filter(se => se.id == seId)[0] || def;
	},
	getSearchEnginesItems(opts = {}) {
		return this.app.searchEngines.map(se => {
			let key = se.id;
			if (opts.withDefault && key === 1) {
				key = void 0;
			}
			let country = this.app.geoMap[se.geo.toUpperCase()] || se.geo;
			let val = se.domain;
			let displayVal = se.domain;
			if (country) {
				val += ' - ' + country;
			}
			val += ' - ' + se.lang;
			displayVal += ' - ' + se.lang;
			let sVal = val + ' #' + se.id + '#';
			return {key, val, displayVal, sVal};
		});
	},
	isObjectEmpty(obj) {
		if (!obj) return true;
		return Object.keys(obj).length === 0;
	},
	getGeoFromPhone(number) {
		number = this.normalizePhone(number);
		for (let len = 6; len > 0; len--) {
			let code = parseInt(number.slice(0, len));
			if (this.phoneCodeGeoMap[code]) return this.phoneCodeGeoMap[code];
		}
		return null;
	},
	normalizePhone(raw) {
		if (!raw) return '';
		return '+' + String(raw).replace(/[^0-9]/g, '');
	},
	sortTable(col, isReverse = false) {
		let sort = col;
		let sortDesc = isReverse;
		let qSort = this.app.query.sort;
		if (qSort && qSort.charAt(0) === '-') {
			qSort = qSort.slice(1);
			sortDesc = !sortDesc;
		}

		if (sortDesc) {
			sort = '-' + sort;
		}
		const query = {...this.app.query, sort};
		this.$router.push({query});
	},
	getLanguagesSuggestions() {
		let items = Object.keys(this.langsMap).map(key => {
			let val = this.langsMap[key];
			let sortVal = val;
			return {key, val, sortVal};
		});
		return items;
	},
	getPlans() {
		let plans = [
			{key: 'analytics', val: 'Analytics'},
			{key: 'autoseo', val: 'AutoSEO'},
			{key: 'fullseo', val: 'FullSEO'},
			{key: 'video', val: 'Video'},
			{key: 'all', val: 'All inclusive'},
			{key: 'additional', val: 'Additional'},
			{key: 'webdev', val: 'WebDev'},
			{key: 'smm', val: 'SMM'},
			{key: 'ecommerce', val: 'E-Commerce'},
			{key: 'email', val: 'E-mail marketing'},
			{key: 'amazon', val: 'Amazon'}
		];
		return plans;
	},
	getProxyUrl(url, handler = null) {
		let domain = this.getDomainFromUrl(url);
		if (domain === location.host) return url;

		let proxyUrl = '/api/proxy?u=' + encodeURIComponent(url);
		if (handler) {
			proxyUrl += '&handler=' + encodeURIComponent(handler)
		}
		return proxyUrl;
	},
	urlEnc(raw, old = false) {
		let param = encodeURIComponent(raw);
		if (old) {
			param = param.replace(/%20/g, '+');
		}
		return param;
	},
	urlDec(raw, old = false) {
		let param = decodeURIComponent(raw);
		if (old) {
			param = param.replace(/\+/g, ' ');
		}
		return param;
	},
	parseQueryString(qs = null) {
		if (qs === null) {
			qs = location.search;
		}
		if (qs[0] === '?') {
			qs = qs.slice(1);
		}
		let obj = {};
		qs.split('&').forEach(pair => {
			let m = pair.match(/^(.*?)=(.*)/);
			if (!m || !m[1]) return;

			let key = this.urlDec(m[1]);
			let val = this.urlDec(m[2]);
			obj[key] = val;
		});
		return obj;
	},
	buildQueryString(obj) {
		let qs = Object.keys(obj).map(key => {
			let val = obj[key];
			if (val == null) return;
			return this.urlEnc(key) + '=' + this.urlEnc(val);
		}).filter(el => el).join('&');
		return qs;
	},
	trim(str) {
		if (!str) return '';
		return String(str).trim();
	},
	isNumeric(input) {
		return !/[^0-9]/.test(input);
	},
	hilite(val, phrase) {
		if (!val || !phrase) return val;

		val = this.escapeHtml(val);
		phrase = this.escapeHtml(phrase);

		let regex = new RegExp('(' + this.escapeRegex(phrase) + ')', 'gi');
		val = val.replace(regex, '<span class="hilite">$1</span>');

		return val;
	},
	escapeRegex(str, delimiter) {
		let regex = new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g');
		return (str + '').replace(regex, '\\$&');
	},
	escapeHtml(html) {
		return html
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&#039;");
	},
	wrapLinks(html) {
		if (!html) return html;

		html = this.escapeHtml(html);
		html = html.replace(/(https?:\/\/[^\s"']+)/gi, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
		return html;
	},
	getDomainFromUrl(url, keepWww = false) {
		let domain = String(url).trim().toLowerCase().replace(/^https?:\/\//i, '').replace(/\/.*/, '');
		if (!keepWww) {
			domain = domain.replace(/^www\./i, '');
		}
		return domain;
	},
	getPosStyle(pos) {
		const naStyle = {
			color: '#000'
		};
		const noPosStyle = {
			color: '#fff',
			backgroundColor: '#ccc'
		};

		if (pos == null) return naStyle;
		if (!pos || pos === 200) return noPosStyle;

		const goodColor = '18ad06';
		const badColor = 'ff0000';

		const goodRgb = goodColor.match(/../g).map(part => parseInt(part, 16));
		const badRgb = badColor.match(/../g).map(part => parseInt(part, 16));

		let avgRgb = [];
		for (let i = 0; i < 3; i++) {
			let avg = Math.floor(badRgb[i] + (goodRgb[i] - badRgb[i]) * (101 - pos) / 100);
			avgRgb.push(avg);
		}

		let hexFull = '#' + avgRgb
				.map(dec => dec.toString(16))
				.map(hex => hex.length === 1 ? ('0' + hex) : hex)
				.join('');

		return {
			color: '#fff',
			backgroundColor: hexFull
		};
	},
	scrollLeft() {
		return window.scrollX != null ? window.scrollX : window.pageXOffset;
	},
	scrollTop() {
		return window.scrollY != null ? window.scrollY : window.pageYOffset;
	},
	filterUnique(value, index, self) {
		return self.indexOf(value) === index;
	},
	padLeft(text, len, char = '0') {
		text = String(text);
		while (text.length < len) {
			text = char + text;
		}
		return text;
	},
	sleep(timeout) {
		return new Promise(function(resolve) {
			setTimeout(resolve, timeout);
		});
	},
	numFormat(val, sep = ',') {
		let parts = String(val).split('.', 2);
		let text = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + sep);
		if (parts[1]) text += '.' + parts[1];
		return text;
	},
	getWinWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	},
	getWinHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	},
	preventDefault: e => e.preventDefault(),
}