var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-header", on: { mouseleave: _vm.onHeaderLeave } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.langPanelShown,
                expression: "langPanelShown"
              },
              {
                name: "mousedown-outside",
                rawName: "v-mousedown-outside",
                value: _vm.hideLangPanel,
                expression: "hideLangPanel"
              }
            ],
            staticClass: "header-panel anim-from-bottom"
          },
          [
            _c(
              "div",
              { staticClass: "lang-panel-inner" },
              _vm._l(_vm.langs, function(lang) {
                return _c(
                  "div",
                  { staticClass: "lang-row" },
                  [
                    _c("base-flag", {
                      attrs: { cc: lang.flag, "no-title": "1" }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(lang.name))])
                  ],
                  1
                )
              })
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.balancePanelShown,
                expression: "balancePanelShown"
              },
              {
                name: "mousedown-outside",
                rawName: "v-mousedown-outside",
                value: _vm.hideBalancePanel,
                expression: "hideBalancePanel"
              }
            ],
            staticClass: "header-panel anim-from-bottom"
          },
          [
            _c("div", { staticClass: "balance-panel-inner" }, [
              _c("div", { staticClass: "general-upper" }, [
                _vm._v("Add balance:")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.balanceToAdd,
                        expression: "balanceToAdd"
                      }
                    ],
                    ref: "accPayTf",
                    staticClass: "acc-pay-tf",
                    attrs: { type: "number" },
                    domProps: { value: _vm.balanceToAdd },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.balanceToAdd = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "base-btn",
                    {
                      attrs: { size: "small" },
                      nativeOn: {
                        click: function($event) {
                          _vm.pay()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-dollar",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      }),
                      _vm._v("\n\t\t\t\t\t\tPay\n\t\t\t\t\t")
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.user && _vm.accPanelShown,
                expression: "user && accPanelShown"
              },
              {
                name: "mousedown-outside",
                rawName: "v-mousedown-outside",
                value: _vm.hideAccPanel,
                expression: "hideAccPanel"
              }
            ],
            staticClass: "header-panel anim-from-bottom"
          },
          [
            _vm.user
              ? _c("div", { staticClass: "acc-panel-inner" }, [
                  _c("div", { staticClass: "acc-userinfo" }, [
                    _vm._v(_vm._s(_vm.user.email))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "base-link",
                        {
                          staticClass: "no-line acc-settings",
                          attrs: { href: "/settings" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-cog" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Settings")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "acc-logout",
                          on: { click: _vm.onLogoutClick }
                        },
                        [
                          _c("i", { staticClass: "fa fa-sign-out" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Logout")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _c("div", [
                  _c("input", {
                    attrs: { type: "button", value: "Login" },
                    on: { click: _vm.onLoginClick }
                  })
                ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "headers" },
        [
          _c(
            "div",
            {
              ref: "header",
              staticClass: "header",
              class: _vm.getSidebarClass()
            },
            [
              _c(
                "div",
                { staticClass: "logo-box" },
                [
                  _c(
                    "base-link",
                    { staticClass: "logo", attrs: { href: "/" } },
                    [
                      _c("img", {
                        attrs: { src: "/img/semalt-logo-white.png", alt: "" }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.pages, function(page) {
                  return _c(
                    "li",
                    [
                      _c(
                        "base-link",
                        {
                          attrs: { href: page.url },
                          nativeOn: {
                            mouseover: function($event) {
                              _vm.onTopMenuItemOver($event, page)
                            }
                          }
                        },
                        [_vm._v(_vm._s(page.label))]
                      )
                    ],
                    1
                  )
                })
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header-right" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "header-lang",
                      on: { click: _vm.showLangPanel }
                    },
                    [
                      _c("base-flag", { attrs: { cc: "gb", "no-title": "1" } }),
                      _vm._v(" "),
                      _c("i", { staticClass: "zmdi zmdi-chevron-down" })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.idu
                    ? [
                        _c(
                          "span",
                          {
                            staticClass: "header-balance",
                            on: { click: _vm.showBalancePanel }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t$ " +
                                _vm._s(_vm.user.balanceUsd) +
                                "\n\t\t\t\t\t"
                            ),
                            _c("i", { staticClass: "zmdi zmdi-chevron-down" })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "header-acc",
                            on: { click: _vm.showAccPanel }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.user.name) +
                                "\n\t\t\t\t\t"
                            ),
                            _c("i", { staticClass: "zmdi zmdi-chevron-down" })
                          ]
                        )
                      ]
                    : _vm.idu === 0
                      ? _c(
                          "span",
                          {
                            staticClass: "header-login-btn",
                            on: { click: _vm.onLoginClick }
                          },
                          [_vm._v("Login")]
                        )
                      : _c("span", [_vm._v(" ")])
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.topSubPages,
                    expression: "topSubPages"
                  }
                ],
                staticClass: "sub-header"
              },
              _vm._l(_vm.topSubPages, function(page) {
                return _c(
                  "base-link",
                  { key: page.url, attrs: { href: page.url } },
                  [_vm._v(_vm._s(page.label))]
                )
              })
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }