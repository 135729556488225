<template>
	<div class="the-footer" :class="getSidebarClass()">
		<div class="footer-inner">
			<div class="footer-grid row xs-6">
				<div class="col">
					<h4>Semalt company</h4>
					<ul>
						<li><a href="">What is Semalt</a></li>
						<li><a href="">About us</a></li>
						<li><a href="">Pricing</a></li>
						<li><a href="">Crawler</a></li>
						<li><a href="">Blog</a></li>
						<li><a href="">Help Center</a></li>
						<li><a href="">Contacts</a></li>
					</ul>
				</div>
				<div class="col">
					<h4>Semalt media</h4>
					<ul>
						<li><a href="">What is Explainer Video</a></li>
						<li><a href="">Free video tool</a></li>
						<li><a href="">Template video</a></li>
						<li><a href="">Video Portfolio</a></li>
						<li><a href="">Clients videos</a></li>
						<li><a href="">Pricing</a></li>
						<li><a href="">Questions & Answers</a></li>
					</ul>
				</div>
				<div class="col">
					<h4>Products</h4>
					<ul>
						<li><a href="">AutoSEO</a></li>
						<li><a href="">FullSEO</a></li>
						<li><a href="">Analytics</a></li>
						<li><a href="">Web development</a></li>
						<li><a href="">Video Production</a></li>
					</ul>
				</div>
				<div class="col">
					<h4>Contacts</h4>
					<ul>
						<li><a href=""></a></li>
						<li><a href=""></a></li>
						<li><a href=""></a></li>
						<li><a href=""></a></li>
						<li><a href=""></a></li>
						<li><a href=""></a></li>
					</ul>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="left">&copy; 2013 - 2018, Semalt.com. All rights reserved</div>
				<div class="right footer-bottom-links">
					<a href="">Privacy policy</a>
					<a href="">Terms of service</a>
					<a href="">Public offer agreement</a>
					<a href="">Refund policy</a>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/mixin";

	.the-footer {
		position: absolute;
		margin-top: -@footerHeight;
		padding: 0 0 0 @sidebarWidth;
		width: 100%;
		height: @footerHeight;
		line-height: @footerHeight;
		text-align: center;
		background: #fff;

		& > * {
			line-height: normal;
		}

		.footer-inner {
			position: relative;
			height: @footerHeight;
		}

		.footer-grid {
			text-align: left;

			h4 {
				margin: 0;
				padding: 20px 0 15px;
				font-size: 15px;
				font-weight: 500;
			}

			& > div {
				padding: 0 0 0 60px;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					padding: 3px 0;
				}
			}
		}

		.footer-bottom {
			position: absolute;
			padding: 0 15px;
			width: 100%;
			height: 40px;
			line-height: 40px;
			bottom: 0;
			color: #fff;
			background: #a5a5a5;

			.footer-bottom-links a {
				margin-left: 10px;
				color: #fff;
				text-transform: uppercase;
			}
		}

		&.sidebar-minimized {
			padding: 0 0 0 @sidebarMinimWidth;
		}
		&.sidebar-hidden {
			padding: 0;
		}
	}
</style>

<script>
	export default {
		props: ['sidebar-status'],
		methods: {
			getSidebarClass() {
				switch (this.sidebarStatus) {
					case 'MINIMIZED': return 'sidebar-minimized';
					case 'HIDDEN': return 'sidebar-hidden';
				}
			}
		}
	}
</script>