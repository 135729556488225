<template>
	<div class="the-header" @mouseleave="onHeaderLeave">
		<transition name="fade">
			<div v-show="langPanelShown" v-mousedown-outside="hideLangPanel" class="header-panel anim-from-bottom">
				<div class="lang-panel-inner">
					<div v-for="lang in langs" class="lang-row">
						<base-flag :cc="lang.flag" no-title="1"></base-flag>
						<span>{{ lang.name }}</span>
					</div>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="balancePanelShown" v-mousedown-outside="hideBalancePanel" class="header-panel anim-from-bottom">
				<div class="balance-panel-inner">
					<div class="general-upper">Add balance:</div>
					<div>
						<input type="number" class="acc-pay-tf" v-model="balanceToAdd" ref="accPayTf">
						<base-btn size="small" @click.native="pay()">
							<i slot="icon" class="fa fa-dollar"></i>
							Pay
						</base-btn>
					</div>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="user && accPanelShown" v-mousedown-outside="hideAccPanel" class="header-panel anim-from-bottom">
				<div v-if="user" class="acc-panel-inner">
					<div class="acc-userinfo">{{ user.email }}</div>
					<div class="clearfix">
						<base-link href="/settings" class="no-line acc-settings">
							<i class="fa fa-cog"></i>
							<span>Settings</span>
						</base-link>
						<span class="acc-logout" @click="onLogoutClick">
						<i class="fa fa-sign-out"></i>
						<span>Logout</span>
					</span>
					</div>
				</div>
				<div v-else>
					<input type="button" value="Login" @click="onLoginClick">
				</div>
			</div>
		</transition>
		<div class="headers">
			<div class="header" :class="getSidebarClass()" ref="header">
				<div class="logo-box">
					<base-link href="/" class="logo">
						<img src="/img/semalt-logo-white.png" alt="">
					</base-link>
				</div>
				<ul>
					<li v-for="page in pages">
						<base-link :href="page.url" @mouseover.native="onTopMenuItemOver($event, page)">{{ page.label }}</base-link>
					</li>
				</ul>
				<div class="header-right">
				<span class="header-lang" @click="showLangPanel">
					<base-flag cc="gb" no-title="1"></base-flag>
					<i class="zmdi zmdi-chevron-down"></i>
				</span>
					<template v-if="idu">
					<span class="header-balance" @click="showBalancePanel">
						$ {{ user.balanceUsd }}
						<i class="zmdi zmdi-chevron-down"></i>
					</span>
						<span class="header-acc" @click="showAccPanel">
						{{ user.name }}
						<i class="zmdi zmdi-chevron-down"></i>
					</span>
					</template>
					<span v-else-if="idu === 0" class="header-login-btn" @click="onLoginClick">Login</span>
					<span v-else>&nbsp;</span>
				</div>
			</div>
			<transition name="fade">
				<div v-show="topSubPages" class="sub-header">
					<base-link v-for="page in topSubPages" :href="page.url" :key="page.url">{{ page.label }}</base-link>
				</div>
			</transition>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/mixin";

	.the-header {
		.header {
			position: fixed;
			padding: 0 0 0 @sidebarWidth;
			width: 100%;
			height: 50px;
			background: #3c6375;
			z-index: 10;
			.box-shadow(0 0 20px rgba(0, 0, 0, 0.5));
			.transition(all 0.2s);

			&.sidebar-minimized {
				padding: 0 0 0 @sidebarMinimWidth;

				.logo-box {
					opacity: 0;
					visibility: hidden;
				}
			}
			&.sidebar-hidden {
				padding: 0 0 0 @sidebarWidth;

				.logo-box {
					opacity: 1;
					visibility: visible;
				}
			}

			.logo-box {
				position: absolute;
				left: 0;
				width: @sidebarWidth;
				text-align: center;
				line-height: 50px;
				.transition(all 0.2s);

				a {
					display: inline-block;
					text-transform: uppercase;
					text-decoration: none;
					font-size: 30px;
					font-weight: bold;
					color: #000;
					vertical-align: top;

					img {
						height: 24px;
					}
				}
			}

			ul {
				float: left;
				margin: 0;
				padding: 11px;
				list-style: none;

				li {
					margin: 0;
					padding: 0;
					display: inline-block;

					a {
						display: inline-block;
						padding: 0 20px;
						line-height: 28px;
						color: #fff;
						font-weight: 500;
						text-decoration: none;
						text-transform: uppercase;
						border-radius: 14px;
					}
				}
			}

			.header-right {
				float: right;
				padding: 11px 18px 0 0;

				.header-lang, .header-balance, .header-acc {
					margin-left: 15px;
					font-size: 15px;
					cursor: pointer;
					color: #fff;

					i {
						position: relative;
						top: 3px;
						font-size: 22px;
					}
				}
				.header-login-btn {
					margin-left: 15px;
					text-transform: uppercase;
					cursor: pointer;
					color: #fff;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.lang-panel-inner {
			min-width: 100px;

			.lang-row {
				padding: 2px 0;
				text-transform: uppercase;
				cursor: pointer;

				&:hover {
					background: #eee;
				}
			}
		}

		.balance-panel-inner {
			.acc-pay-tf {
				width: 120px;
			}

			button {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.acc-panel-inner {
			.acc-settings {
				float: left;
			}
			.acc-logout {
				float: right;
			}
			.acc-settings, .acc-logout {
				cursor: pointer;
				color: @primColor;
				font-weight: 500;
				font-size: 12px;
				text-transform: uppercase;

				&:hover span {
					text-decoration: underline;
				}
			}

			.acc-userinfo {
				padding: 0 0 5px 0;
				width: 150px;
			}
		}

		.header-panel {
			position: fixed;
			right: 10px;
			top: 60px;
			padding: 10px;
			background: #fff;
			border: 1px solid #ccc;
			z-index: 100;
			.box-shadow();
		}

		.sub-header {
			@left: @sidebarWidth + 30px;

			position: fixed;
			top: 50px;
			padding: 10px 0 0 @left;
			width: 100%;
			height: 40px;
			background: #1c4050;
			z-index: 9;

			a {
				display: inline-block;
				margin: 0 10px;
				color: #fff;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
</style>

<script>
	export default {
		props: ['user', 'pages', 'sidebar-status'],
		data() {
			return {
				topSubPages: null,
				langPanelShown: false,
				accPanelShown: false,
				balancePanelShown: false,
				balanceToAdd: 100,
				langs: [
					{flag: 'gb', name: 'English'},
					{flag: 'es', name: 'Spanish'},
					{flag: 'it', name: 'Italian'},
					{flag: 'tr', name: 'Turkish'},
					{flag: 'ru', name: 'Russian'}
				]
			};
		},
		computed: {
			idu() {
				if (!this.user) return null;
				return this.user.id;
			}
		},
		methods: {
			getSidebarClass() {
				switch (this.sidebarStatus) {
					case 'MINIMIZED': return 'sidebar-minimized';
					case 'HIDDEN': return 'sidebar-hidden';
				}
			},
			showLangPanel() {
				this.langPanelShown = true;
			},
			hideLangPanel() {
				this.langPanelShown = false;
			},
			onTopMenuItemOver(e, page) {
				this.topSubPages = page.sub;
			},
			onHeaderLeave() {
				this.topSubPages = null
			},
			showBalancePanel() {
				this.balancePanelShown = true;
				this.$nextTick(() => {
					this.$refs.accPayTf.focus();
				});
			},
			hideBalancePanel() {
				this.balancePanelShown = false;
			},
			showAccPanel() {
				this.accPanelShown = true;
			},
			hideAccPanel() {
				this.accPanelShown = false;
			},
			pay() {
				this.$emit('pay', {
					amount: this.balanceToAdd
				});
			},
			onLoginClick() {
				this.$emit('login');
			},
			onLogoutClick() {
				this.$emit('logout');
			}
		}
	}
</script>