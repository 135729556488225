var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-footer", class: _vm.getSidebarClass() },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-inner" }, [
      _c("div", { staticClass: "footer-grid row xs-6" }, [
        _c("div", { staticClass: "col" }, [
          _c("h4", [_vm._v("Semalt company")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("What is Semalt")])
            ]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("About us")])]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Pricing")])]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Crawler")])]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Blog")])]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Help Center")])
            ]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Contacts")])])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("h4", [_vm._v("Semalt media")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("a", { attrs: { href: "" } }, [
                _vm._v("What is Explainer Video")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Free video tool")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Template video")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Video Portfolio")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Clients videos")])
            ]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Pricing")])]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Questions & Answers")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("h4", [_vm._v("Products")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("AutoSEO")])]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("FullSEO")])]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } }, [_vm._v("Analytics")])]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Web development")])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "" } }, [_vm._v("Video Production")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("h4", [_vm._v("Contacts")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_c("a", { attrs: { href: "" } })]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } })]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } })]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } })]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } })]),
            _vm._v(" "),
            _c("li", [_c("a", { attrs: { href: "" } })])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-bottom" }, [
        _c("div", { staticClass: "left" }, [
          _vm._v("© 2013 - 2018, Semalt.com. All rights reserved")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right footer-bottom-links" }, [
          _c("a", { attrs: { href: "" } }, [_vm._v("Privacy policy")]),
          _vm._v(" "),
          _c("a", { attrs: { href: "" } }, [_vm._v("Terms of service")]),
          _vm._v(" "),
          _c("a", { attrs: { href: "" } }, [_vm._v("Public offer agreement")]),
          _vm._v(" "),
          _c("a", { attrs: { href: "" } }, [_vm._v("Refund policy")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }