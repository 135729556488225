<template>
	<div class="color-picker">
		<div class="field">
			<canvas
				ref="field"
				width="200"
				height="200"
				@mousedown="fieldDrug = 1"
				@mouseup="fieldDrug = 0"
				@mousemove="pickBrightness"
			></canvas>
			<div
				class="picker"
				:style="'top: ' + (field.y - 7.5) + 'px; left: ' + (field.x - 7.5) + 'px;'"
			></div>
		</div>
		<canvas
			ref="stripe"
			class="stripe"
			width="50"
			height="200"
			@mousedown="stripeDrug = 1"
			@mouseup="stripeDrug = 0"
			@mousemove="pickColor"
		></canvas>
	</div>
</template>

<script>
	export default {
		props: ['value'],
		data() {
			return {
				blockStripe: null,
				blockField: null,
				stripe: {},
				field: {},
				stripeDrug: 0,
				fieldDrug: 0,
				color: ''
			};
		},
		watch: {
			value(color) {
				let r,g,b;

				let	ifHEX = /#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(color) || /#([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})/i.exec(color);
				if (ifHEX) {
					let [h,e,x] = [...ifHEX.slice(1,4)];
					r = this.from16(h);
					g = this.from16(e);
					b = this.from16(x);
				}

				let ifRGB = /rgb\((\d{1,3}),\D*(\d{1,3}),\D*(\d{1,3})\)/i.exec(color);
				if (ifRGB) {
					[r,g,b] = [...ifRGB.slice(1,4)];
				}

				let ifHSL = /hsl\((\d{1,3}),\D*(\d{1,3})\%*,\D*(\d{1,3})\%*\)/i.exec(color);
				if (ifHSL) {
					[r,g,b] = [...this.HSLToRGB(...ifHSL.slice(1,4)).vals];
				}

				r = r > 128 ? 255 : 0;
				g = g > 128 ? 255 : 0;
				b = b > 128 ? 255 : 0;

				// this.fillFieldGradient(`rgb(${r},${g},${b})`);
			}
		},
		methods: {
			emitInput(color) {
				this.$emit('input', color);
			},
			getColor() {
				let [r, g, b] = this.field.ctx.getImageData(this.field.x, this.field.y, 1, 1).data;
				this.emitInput(`rgb(${r}, ${g}, ${b})`);
			},
			pickColor(e) {
				if (this.stripeDrug) {
					this.stripe.x = e.offsetX;
					this.stripe.y = e.offsetY;
					let [r,g,b] = this.stripe.ctx.getImageData(this.stripe.x, this.stripe.y, 1, 1).data;
					this.fillFieldGradient(`rgb(${r},${g},${b})`);
					this.getColor();
				}
			},
			pickBrightness(e) {
				if (this.fieldDrug) {
					this.field.x = e.offsetX;
					this.field.y = e.offsetY;
					let [r, g, b] = this.field.ctx.getImageData(this.field.x, this.field.y, 1, 1).data;
					console.log('r', r, 'g', g,'b', b,'coords', e.offsetX, e.offsetY);
					this.emitInput(`rgb(${r}, ${g}, ${b})`);
				}
			},
			fillFieldGradient(color) {

				let {ctx, width, height} = this.field;

				ctx.clearRect(0, 0, width, height);
				ctx.fillStyle = color;
				ctx.fillRect(0, 0, width, height);

				let gr = ctx.createLinearGradient(0, 0, width, height);

				gr.addColorStop(0, 	'rgba(0, 0, 0, 1)');
				if (color) gr.addColorStop(0.5, color);
				gr.addColorStop(1, 	'rgba(255, 255, 255, 1)');

				ctx.fillStyle = gr;
				ctx.fillRect(0, 0, width, height);
				ctx.strokeRect(0, 0, width, height);
			},
			stripeGradient() {
				let {ctx,width, height} = this.stripe;
				let gradient = ctx.createLinearGradient(0, 0, 0, height);

				gradient.addColorStop(0, 	'rgb(255, 0, 0)');
				gradient.addColorStop(0.17, 'rgb(255, 255, 0)');
				gradient.addColorStop(0.35,	'rgb(0, 255, 0)');
				gradient.addColorStop(0.5, 	'rgb(0, 255, 255)');
				gradient.addColorStop(0.67, 'rgb(0, 0, 255)');
				gradient.addColorStop(0.84, 'rgb(255, 0, 255)');
				gradient.addColorStop(1, 	'rgb(255, 0, 0)');

				ctx.fillStyle = gradient;
				ctx.fillRect(0,0,width,height);
				ctx.strokeRect(0,0,width,height);

				this.fillFieldGradient('red');
			},
			to16(dec) {
				return Number(dec).toString(16).padStart(2, '0')
			},
			from16(hex) {
				return parseInt(hex, 16)
			},
			HSLToRGB (...hsl) {
				let [h,s,l] = [...hsl.map(e => +e)];
				h = h / 360;
				s = s / 100;
				l = l / 100;

				let r, g, b;

				if(s === 0){
					r = g = b = l;
				}
				else{
					let hue2rgb = (p, q, t) => {
						if(t < 0) t += 1;
						if(t > 1) t -= 1;
						if(t < 1/6) return p + (q - p) * 6 * t;
						if(t < 1/2) return q;
						if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
						return p;
					};

					let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
					let p = 2 * l - q;
					r = Math.round(hue2rgb(p, q, h + 1/3) * 255);
					g = Math.round(hue2rgb(p, q, h) * 255);
					b = Math.round(hue2rgb(p, q, h - 1/3) * 255);
				}

				return {
					str: `rgb(${r}, ${g}, ${b})`,
					vals: [r, g, b]
				};

			},
			init() {
				this.blockField = this.$refs.field;
				this.blockStripe = this.$refs.stripe;

				this.stripe = {
					ctx: this.blockStripe.getContext('2d'),
					width: this.blockStripe.width,
					height: this.blockStripe.height,
					x: 0,
					y: 0
				};
				this.field = {
					ctx: this.blockField.getContext('2d'),
					width: this.blockField.width,
					height: this.blockField.height,
					x: 128,
					y: 128
				};

				this.stripeGradient()
			}
		},
		mounted() {
			this.init();
		}
	}
</script>

<style lang="less">
	.color-picker {
		margin: auto;
		width: 290px;
		height: 200px;
		background-color: rgba(112,0,115,0.2);
		border-radius: 5px;
		transition: all ease 0.2s;

		.field, .stripe {
			float: left;
		}

		.color, .hex {
			height: 50px;
			width: 80px;
			border: 1px solid black;
			margin: 9px;
			font-family: Helvetica, Arial, sans-serif;
			text-align: center;
			font-size: 16px;
			font-weight: bold;
			line-height: 50px;
		}
		.field, .stripe {
			margin: 0 10px;
			position: relative;
		}

		.picker {
			width: 15px;
			height: 15px;
			border: 2px solid;
			border-radius: 50%;
			box-shadow: 0 0 0 1px black;
			color: white;
			position: absolute;
			top: 264px;
			left: 914px;
			z-index: 1;
			pointer-events: none;
		}
	}
</style>